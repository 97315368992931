/**
 * External dependencies
 */
import React from 'react';
import _ from 'lodash';
import * as rdd from 'react-device-detect';
/**
 * Internal dependencies
 */
import ResultItem from 'components/result-item/result-item';
import LoadingProvider from 'components/loading-provider/loading-provider';
import getRandomColor from 'helpers/get-random-color';
import useAllScenes from '../hooks/use-all-scenes';
import Card from '../components/card';
import useAuth from '../../auth/hooks/use-auth';

const AllVibes = ({ deviceId }) => {
  const { previewUser } = useAuth();
  const path = previewUser ? `user/${deviceId}` : deviceId;

  const { allScenes, allFeaturedScenes, loading } = useAllScenes({
    deviceId,
  });
  const allMixes = [...allScenes, ...allFeaturedScenes];

  const allVibes = _.uniq(
    _.flattenDeep(allMixes?.map((scene) => [...scene.vibe] || [])).map((item) =>
      item.toUpperCase()
    )
  );

  return (
    <LoadingProvider loading={loading}>
      {rdd?.isMobileOnly ? (
        <div
          style={{
            width: '100vw',
            marginBottom: 135,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: 13,
            padding: '0px 13px',
          }}
        >
          {!loading &&
            allVibes?.sort().map((vibe, index) => {
              return (
                <Card
                  index={index}
                  key={vibe}
                  title={vibe}
                  to={`/${path}/search/all/vibe/${vibe}`}
                />
              );
            })}
        </div>
      ) : (
        <div className="list-results-small">
          {!loading &&
            allVibes?.length > 0 &&
            allVibes
              .sort()
              .map((item) => (
                <ResultItem
                  color={getRandomColor(_.random(14))}
                  key={item}
                  to={`/${path}/search/all/vibe/${item}`}
                  title={item}
                  hasImage
                />
              ))}
        </div>
      )}
    </LoadingProvider>
  );
};

export default AllVibes;
