import React, { useContext } from 'react';
import BoardingWrapper from '../boarding-wrapper';
import { BoardingContext } from '../context/boardingContext';
import InputBoarding from './input-boarding';
import emailPattern from '../../../helpers/email-pattern';
import { toast } from 'react-toastify';

const BoardingStart = ({ stack }) => {
  const {
    name,
    setName,
    companyName,
    setCompanyName,
    companyWebsite,
    setCompanyWebsite,
    emailAddress,
    setEmailAddress,
  } = useContext(BoardingContext);

  const notify = (message) =>
    toast(message, {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: true,
      toastId: 'email-error',
      bodyStyle: { color: '#FF4000' },
    });

  return (
    <BoardingWrapper
      stack={stack}
      disabled={
        name?.length < 2 ||
        companyName?.length < 2 ||
        emailPattern?.test(emailAddress) === false
      }
      progress={{ firstBar: '33', secondBar: '0', thirdBar: '0' }}
    >
      <div className="modal-boarding">
        <p className="modal-boarding__title-small">Hi there 👋</p>
        <p className="modal-boarding__title-small">
          Let’s start curating! In the next few steps we’ll get to know more
          about your business.
        </p>
        <InputBoarding
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
          style={{ marginTop: '10px' }}
          onBlur={() => {
            if (name.length < 2) {
              !notify('You need to enter a valid name!');
            }
          }}
        />
        <InputBoarding
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          placeholder="Company Name"
          onBlur={() => {
            if (companyName.length < 2) {
              notify('You need to enter a valid company name!');
            }
          }}
        />
        <InputBoarding
          value={companyWebsite}
          onChange={(e) => setCompanyWebsite(e.target.value)}
          placeholder="Company Website"
        />
        <InputBoarding
          value={emailAddress}
          onChange={(e) => setEmailAddress(e.target.value)}
          onBlur={() => {
            if (emailPattern.test(emailAddress) === false) {
              notify('Invalid email address!');
            }
          }}
          placeholder="Email Address"
        />
        <p className="modal-boarding__subtitle">
          We’ll use this information to curate a personalized program for you
          from our library of Playlists and Scenes. This first step is the
          starting point in your musical journey with us. After this initial
          step, you will work directly with our curation team to create an
          experience that genuinely reflects your brand.
        </p>
      </div>
    </BoardingWrapper>
  );
};

export default BoardingStart;
