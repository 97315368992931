/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const PREVIEW_TOKEN = gql`
  query FetchPreviewToken($previewId: String!, $type: String) {
    viewer {
      fetchPreviewToken(previewId: $previewId, type: $type)
    }
  }
`;
