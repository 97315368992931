/**
 * External dependencies
 */
import React, { useState } from 'react';

import Button from '../../components/button/button';

import useUpdateDevicePlaylist from '../../domain/dashboard/hooks/use-update-device-playlist';
import LoadingProvider from '../../components/loading-provider/loading-provider';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IcoCurator } from '../../assets/svg-icons/Curator-dot.svg';

import getRandomColor from '../../helpers/get-random-color';
import _ from 'lodash';

const Playlist = ({
  subtitle,
  name,
  title,
  description,
  children,
  color,
  cover,
  device,
  devicePlayType,
  playlistId,
  showPreviewButton,
  handlePreview,
}) => {
  const history = useHistory();
  const { updateDevicePlaylist, loading, data } = useUpdateDevicePlaylist();
  const [randomColor, setRandomColor] = useState(getRandomColor(_.random(14)));

  const handlePlayNow = async () => {
    if (device) {
      await updateDevicePlaylist({
        variables: {
          deviceId: device.id,
          devicePlayType: devicePlayType,
          devicePlayTypeId: playlistId,
        },
      }).then((res) => {
        console.log(res);
        history.push(`/${device.id}`);
      });
    }
  };
  //   show loader when click play then redirect to home
  return (
    <LoadingProvider loading={!children || loading}>
      <div className="playlist">
        <div
          className="playlist__card"
          style={{ backgroundColor: randomColor }}
        >
          <aside className="playlist__card-aside">
            {cover && <img src={cover} alt={name} />}
            <IcoCurator
              style={{ position: 'absolute', height: 50, bottom: 20 }}
            />
            {subtitle && <h5>{subtitle}</h5>}
            {name && <h2>{name}</h2>}
          </aside>

          <div className="playlist__card-content">
            {title && <h3>{title}</h3>}
            {description && <p>{description}</p>}
            <div style={{ position: 'absolute', bottom: 30 }}>
              {false && playlistId && (
                <Button
                  onClick={handlePlayNow}
                  modifier="primary"
                  style={{ position: 'initial' }}
                >
                  Play Now
                </Button>
              )}
              {playlistId && showPreviewButton && (
                <Button
                  onClick={handlePreview}
                  modifier="primary"
                  style={{ position: 'initial', marginLeft: 10 }}
                >
                  Preview
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className="playlist__body">{children}</div>
      </div>
    </LoadingProvider>
  );
};

export default Playlist;
