import React, { useMemo } from 'react';
import * as rdd from 'react-device-detect';

import DashboardLayout from 'layouts/dashboard-layout';

import LoadingProvider from 'components/loading-provider/loading-provider';
import useAllScenes from '../hooks/use-all-scenes';
import useAllPlaylists from '../hooks/use-all-playlists';
import useAuth from '../../auth/hooks/use-auth';
import toTitleCase from '../../../utils/toTitleCase';
import HelperCardsMobile from '../../sales-wizard/components/header-helper/helper-cards-mobile';

const GeneratedContentActionsPage = () => {
  const { user } = useAuth();
  const { loading: loadingScenes } = useAllScenes();
  const { loading: loadingPlaylists } = useAllPlaylists();

  const userBrandArtists = useMemo(
    () => user?.musicProfile?.artists.filter((item) => item !== '') || [],
    [user?.musicProfile?.artists]
  );
  return (
    <div className="helper-wrapper">
      <DashboardLayout
        subtitle="Music"
        title={toTitleCase(user?.companyName) || ''}
        modifier="music-search"
      >
        <LoadingProvider loading={loadingScenes || loadingPlaylists}>
          <h4
            style={{
              marginBottom: 20,
              fontSize: 16,
              ...(rdd.isMobileOnly && { marginLeft: 20 }),
            }}
          />
          <HelperCardsMobile />
        </LoadingProvider>
      </DashboardLayout>
    </div>
  );
};

export default GeneratedContentActionsPage;
