/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const ALL_GENRES = gql`
  query Query(
    $allGenresFirst: Int
    $allGenresAfter: String
    $allGenresFilter: GenreFilter
    $allGenresDeviceId: String
  ) {
    viewer {
      allGenres(
        first: $allGenresFirst
        after: $allGenresAfter
        filter: $allGenresFilter
        deviceId: $allGenresDeviceId
      ) {
        edges {
          cursor
          node {
            id
            name
            allPlaylists {
              id
              name
              cover
              description
            }
          }
        }
      }
    }
  }
`;

export const ALL_GENRES_FOR_SONOS = gql`
    query allGenresForSonosQuery {
        viewer {
            allGenresForSonos {
                name
            }
        }
    }
`;
