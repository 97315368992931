/**
 * External dependencies
 */
import React from 'react';
import * as rdd from 'react-device-detect';

/**
 * Internal dependencies
 */
import MusicSearchBrowser from './music-search-browser';
import MusicSearchMobile from './music-search-mobile';

const MusicSearchPage = ({
  match: {
    params: { id, searchTerm },
  },
  location: { pathname },
}) => {
  return (
    <>
      {rdd.isMobileOnly ? (
        <MusicSearchMobile
          id={id}
          searchTerm={searchTerm}
          pathname={pathname}
        />
      ) : (
        <MusicSearchBrowser
          id={id}
          searchTerm={searchTerm}
          pathname={pathname}
        />
      )}
    </>
  );
};

export default MusicSearchPage;
