import React, { useMemo } from 'react';
import * as rdd from 'react-device-detect';

import LoadingProvider from 'components/loading-provider/loading-provider';
import useAllScenes from '../hooks/use-all-scenes';
import CardLink from 'components/card-link/card-link';

import useAuth from '../../auth/hooks/use-auth';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import Button from '../../../components/button/button';
import { stringSizeLimiter } from '../../../utils/string-size-limiter';

const OnBrandArtists = ({ userBrandArtists }) => {
  let { id } = useParams();
  let history = useHistory();
  const { previewUser } = useAuth();
  const path = previewUser ? `user/${id}` : id;
  const { allArtistScenes, loading: loadingScenes } = useAllScenes();

  const artistString = userBrandArtists.join(' / ');
  const artistStringWLimit = stringSizeLimiter(artistString, 33);

  const scenes = useMemo(() => allArtistScenes || [], [allArtistScenes]);
  const scenesSample = useMemo(() => _.sampleSize(scenes, 8), [scenes]);

  return (
    <>
      {rdd.isMobileOnly ? (
        <div style={{ marginBottom: 39 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 20,
              padding: '0px 13px',
            }}
          >
            <h1 style={{ fontSize: 12, fontWeight: 'normal' }}>
              On brand artists?&nbsp;
            </h1>
            <h2 style={{ flex: 'auto', fontSize: 12 }}>{artistStringWLimit}</h2>
            <h1
              style={{ fontSize: 12, color: '#3BABA1', fontWeight: 'normal' }}
              onClick={() =>
                history.push(`/${path}/generated-content/on-brand-artists`)
              }
            >
              View all
            </h1>
          </div>

          <div
            style={{
              overflow: 'auto',
              whiteSpace: 'nowrap',
              display: 'flex',
            }}
          >
            {scenesSample?.map((item, index) => {
              const to =
                item.type === 'playlist'
                  ? `/${path}/search/playlists/${item.id}`
                  : `/${path}/search/all/scene/${item.id}`;
              return (
                <CardLink
                  key={item.id}
                  handleClick={() => null}
                  to={to}
                  title={item.name}
                  cover={item.cover}
                  style={{
                    height: 225,
                    width: 225,
                    display: 'inline-block',
                    marginLeft: 13,
                    marginRight: index === scenesSample.length - 1 ? 13 : 7,
                  }}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div style={{ marginTop: 38, zIndex: 5 }}>
          <h5>On brand artists</h5>
          <div style={{ display: 'flex' }}>
            <h2 style={{ flex: 'auto' }}>{artistString}</h2>
            <Button
              onClick={() =>
                history.push(`/${path}/generated-content/on-brand-artists`)
              }
              modifier="as-link"
            >
              View all
            </Button>
          </div>
          <LoadingProvider loading={loadingScenes}>
            <h4
              style={{
                marginBottom: 20,
                fontSize: 16,
                ...(rdd.isMobileOnly && { marginLeft: 20 }),
              }}
            />
            <div
              className="list-results"
              style={{
                justifyContent: 'space-between',
                marginBottom: 20,
              }}
            >
              {_.sampleSize(scenes, 8)?.map((item) => {
                const to =
                  item.type === 'playlist'
                    ? `/${path}/search/playlists/${item.id}`
                    : `/${path}/search/all/scene/${item.id}`;
                return (
                  <CardLink
                    key={item.id}
                    to={to}
                    title={item.name}
                    cover={item.cover}
                  />
                );
              })}
            </div>
          </LoadingProvider>
        </div>
      )}
    </>
  );
};

export default OnBrandArtists;
