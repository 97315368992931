import React from 'react';

import Button from '../button/button';
import { useHistory } from 'react-router-dom';
import useAuth from '../../domain/auth/hooks/use-auth';
import toTitleCase from '../../utils/toTitleCase';

const PreviewWelcomeCardMobile = ({ pathname }) => {
  const history = useHistory();
  const { user } = useAuth();

  // make all first letter in user's name uppercase
  const parsedName = toTitleCase(user?.profile?.name);

  return (
    <div
      style={{
        backgroundColor: '#20349F',
        borderRadius: 13,
        display: 'flex',
        flexDirection: 'row',
        margin: 10,
      }}
    >
      <div
        style={{
          flex: 1,
          padding: 25,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <h1 style={{ fontSize: 18, marginBottom: 15, width: 210 }}>
          Welcome to the world of Activaire Curator
        </h1>
        <p style={{ fontSize: 13, marginBottom: 10 }}>
          Hi <b>{parsedName}</b> 👋
          <br /> Thanks for answering our questionnaire. Based on your
          responses, We’ve curated a selection of Playlists and Scenes just for
          you. Click the button below to start exploring. If you’d like to
          browse our full catalog of Playlists and Scenes, scroll down and visit
          the Scenes, Genres, Intensities, and Vibes pages.
        </p>
        <Button
          modifier="white2"
          style={{ width: '100%' }}
          onClick={() => history.push(`${pathname}/generated-content`)}
        >
          My Playlists and Scenes
        </Button>
      </div>
    </div>
  );
};

export default PreviewWelcomeCardMobile;
