/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import Button from 'components/button/button';
import Modal from 'components/modal/modal';

const TrialModal = ({ visible, onClose }) => {
  return (
    <Modal visible={visible} onClose={() => onClose()}>
      <div className="modal-window__container">
        <div className="modal-window__imgcontainer">
          <img
            className="modal-window__graphics2"
            src={require('assets/images/modals/Ellipse 1.png').default}
          />
          <img
            className="modal-window__graphics1"
            src={require('assets/images/modals/carry 1.png').default}
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <p className="modal-window__title">Trial activated</p>
          <p className="modal-window__subtitle">
            You must return to the Sonos app to continue the set up process.
          </p>
          <Button modifier="white2" onClick={() => onClose()}>
            OK!
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TrialModal;
