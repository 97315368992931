/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const UPDATE_MUSIC_PROFILE = gql`
  mutation updateMusicProfile(
    $musicProfileProvider: MusicProfileProvider!
    $socialProfileProvider: SocialProfileProvider!
    $companyName: String!
    $incomingService: String
  ) {
    updateMusicProfile(
      musicProfileProvider: $musicProfileProvider
      socialProfileProvider: $socialProfileProvider
      companyName: $companyName
      incomingService: $incomingService
    ) {
      id
      profile {
        name
        clientId
      }
      favouriteDevices
      emails {
        address
      }
      companyName
      musicProfile {
        category
      }
    }
  }
`;
