import React from 'react';
import Button from '../../../../components/button/button';
import SignUpModal from './sign-up-modal';
import useUserPreviewAction from '../../hooks/use-user-preview-action';

const HelperCard = ({
  title,
  description,
  description2,
  style,
  buttonText,
}) => {
  const [showSignUpModal, setShowSignUpModal] = React.useState(false);
  const [modalColor, setModalColor] = React.useState('#893BC6');
  const { userPreviewAction } = useUserPreviewAction();

  const handleButton = () => {
    switch (buttonText) {
      case 'Get Curator':
        userPreviewAction({
          variables: {
            action: 'getCurator',
          },
        }).then((res) => {
          if (res?.data?.userPreviewAction === 'OK') {
            setModalColor('#893BC6');
            setShowSignUpModal(true);
          }
        });
        break;
      case 'Contact Sales':
        userPreviewAction({
          variables: {
            action: 'contactSales',
          },
        }).then((res) => {
          if (res?.data?.userPreviewAction === 'OK') {
            setModalColor('#20349F');
            setShowSignUpModal(true);
          }
        });
        break;
      case 'Modify':
        window.location.href = `/`;
        break;
      default:
        break;
    }
  };

  return (
    <div className="helper-card" style={style}>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 'auto' }}>
        <h2>{title}</h2>
        <p>{description}</p>
        <p>{description2}</p>
      </div>
      <div style={{ display: 'flex', marginLeft: 20, marginBottom: 20 }}>
        <Button type="submit" modifier="primary" onClick={handleButton}>
          {buttonText}
        </Button>
      </div>
      <SignUpModal
        visible={showSignUpModal}
        cardTitle="Thanks for your interest in Activaire Curator. Our sales team will
            contact you shortly using the contact information you provided."
        cardColor={modalColor}
        onClose={() => setShowSignUpModal(false)}
      />
    </div>
  );
};

export default HelperCard;
