import React, { createContext, useEffect, useState } from 'react';
import useAuth from '../../auth/hooks/use-auth';
import useUser from '../../auth/hooks/use-user';

export const BoardingContext = createContext({});

const stack = [
  'boarding-start',
  'social',
  'category',
  'category-specific',
  'genre',
  'artists',
  'avoid-genre',
  'vibe',
  'explicit',
  'boarding-end',
];

const BoardingContextProvider = ({ children }) => {
  const { previewToken, previewId } = useAuth();
  const { data: user } = useUser();
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSpecificCategory, setSelectedSpecificCategory] = useState('');
  const [otherSpecificCategoryValue, setOtherSpecificCategoryValue] =
    useState('');
  const [otherCategoryValue, setOtherCategoryValue] = useState('');
  const [socialValues, setSocialValues] = useState({
    Twitter: '',
    LinkedIn: '',
    Instagram: '',
    ['Spotify / Apple Music / Tidal']: '',
  });
  const [genreValues, setGenreValues] = useState([]);
  const [artistValues, setArtistValues] = useState({
    0: '',
    1: '',
    2: '',
  });
  const [avoidGenreValues, setAvoidGenreValues] = useState([]);
  const [vibeValues, setVibeValues] = useState([]);
  const [isExplicit, setIsExplicit] = useState(false);

  const [selectedStack, setSelectedStack] = useState(stack[0]);

  /*
    If previewToken is present, we need to update the useState params
    to reflect the data from the previewToken.
    The existing user values from DB will be fetched using this token
  */
  useEffect(() => {
    if (user && previewToken && previewId) {
      setName(user.profile?.name);
      setCompanyName(user?.companyName);
      setCompanyWebsite(user?.profile?.companyWebsite);
      setEmailAddress(user.emails ? user.emails[0]?.address : '');
      setSelectedCategory(user.musicProfile?.category);
      setSelectedSpecificCategory(user.musicProfile?.subCategory);
      setSocialValues({
        Twitter: user.musicProfile?.twitter,
        LinkedIn: user.musicProfile?.linkedIn,
        Instagram: user.musicProfile?.instagram,
        'Spotify / Apple Music / Tidal': user.musicProfile?.spotify,
      });
      setGenreValues(user.musicProfile?.genres);
      setArtistValues(user.musicProfile?.artists);
      setAvoidGenreValues(user.musicProfile?.avoidGenres);
      setVibeValues(user.musicProfile?.vibe);
      setIsExplicit(user.musicProfile?.explicit);
    }
  }, [previewToken, user]);

  const handleSocialInputChange = (e) => {
    const { name, value } = e.target;

    setSocialValues({
      ...socialValues,
      [name]: value,
    });
  };

  const handleArtistInputChange = (e) => {
    const { name, value } = e.target;

    setArtistValues({
      ...artistValues,
      [name]: value,
    });
  };

  const handleGenres = (name) => {
    if (genreValues?.length < 5 && !genreValues?.includes(name)) {
      if (avoidGenreValues?.includes(name)) {
        const newAvoidGenreValues = [...avoidGenreValues];
        const findIndex = newAvoidGenreValues.findIndex(
          (item) => item.toLowerCase() === name.toLowerCase()
        );
        newAvoidGenreValues.splice(findIndex, 1);
        setAvoidGenreValues(newAvoidGenreValues);
      }
      setGenreValues([...genreValues, name]);
    } else {
      const filteredGenres = genreValues?.filter((item) => item !== name) || [];
      setGenreValues(filteredGenres);
    }
  };

  const handleAvoidGenres = (name) => {
    if (!genreValues.includes(name)) {
      if (avoidGenreValues?.length < 5 && !avoidGenreValues?.includes(name)) {
        setAvoidGenreValues([...avoidGenreValues, name]);
      } else {
        const filteredGenres = avoidGenreValues?.filter(
          (item) => item !== name
        );
        setAvoidGenreValues(filteredGenres);
      }
    }
  };

  const handleVibes = (name) => {
    if (!vibeValues?.includes(name)) {
      setVibeValues([...vibeValues, name]);
    } else {
      const filteredVibes = vibeValues?.filter((item) => item !== name);
      setVibeValues(filteredVibes);
    }
  };
  return (
    <BoardingContext.Provider
      value={{
        name,
        setName,
        companyName,
        setCompanyName,
        companyWebsite,
        setCompanyWebsite,
        emailAddress,
        setEmailAddress,
        selectedCategory,
        setSelectedCategory,
        selectedSpecificCategory,
        setSelectedSpecificCategory,
        otherSpecificCategoryValue,
        setOtherSpecificCategoryValue,
        otherCategoryValue,
        setOtherCategoryValue,
        handleSocialInputChange,
        handleArtistInputChange,
        artistValues,
        socialValues,
        genreValues,
        vibeValues,
        avoidGenreValues,
        handleGenres,
        handleAvoidGenres,
        handleVibes,
        isExplicit,
        setIsExplicit,
        selectedStack,
        setSelectedStack,
        stack,
        config: {
          name,
          companyName,
          companyWebsite,
          emailAddress,
          selectedCategory,
          selectedSpecificCategory,
          otherCategoryValue,
          otherSpecificCategoryValue,
          socialValues,
          genreValues,
          avoidGenreValues,
          vibeValues,
          artistValues,
          isExplicit,
        },
      }}
    >
      {children}
    </BoardingContext.Provider>
  );
};

export default BoardingContextProvider;
