import React, { useContext, useEffect } from 'react';
import BoardingWrapper from '../boarding-wrapper';
import { BoardingContext } from '../context/boardingContext';
import Tag from './category/tag';
import InputBoarding from './input-boarding';

const Category = ({ categories }) => {
  const {
    companyName,
    setSelectedCategory,
    selectedCategory,
    setOtherCategoryValue,
    otherCategoryValue,
    setSelectedSpecificCategory,
    selectedSpecificCategory,
  } = useContext(BoardingContext);

  const curatedCategories = [...categories];
  curatedCategories.push({ id: 'other', name: 'Other' });

  const handleCategoryChange = (name, otherValue) => {
    setSelectedCategory(name);
    if (name === 'Other') {
      setOtherCategoryValue(otherValue);
    }
    setSelectedSpecificCategory('');
  };

  useEffect(() => {
    if (selectedSpecificCategory === 'Other') {
      setSelectedCategory('Other');
      setOtherCategoryValue(selectedCategory);
    }
  }, [selectedSpecificCategory]);

  return (
    <BoardingWrapper
      disabled={
        !selectedCategory ||
        (selectedCategory === 'Other' && otherCategoryValue?.length < 3)
      }
      progress={{ firstBar: '100', secondBar: '0', thirdBar: '0' }}
      id="otherCategory"
    >
      <div className="modal-boarding">
        <p className="modal-boarding__subtitle">{companyName}</p>
        <p className="modal-boarding__title">What do you do?</p>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
          }}
        >
          {curatedCategories?.map((item) => (
            <Tag
              key={item.id}
              title={item.name}
              isSelected={selectedCategory === item?.name}
              handleOnClick={() => handleCategoryChange(item?.name, null)}
            />
          ))}
        </div>
        {selectedCategory === 'Other' && (
          <InputBoarding
            onChange={(e) => handleCategoryChange('Other', e.target.value)}
            value={otherCategoryValue}
            placeholder="Your business type"
            style={{ marginTop: 10 }}
          />
        )}
      </div>
    </BoardingWrapper>
  );
};

export default Category;
