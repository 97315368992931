import { gql } from '@apollo/client';

export const UPDATE_DEVICE_PLAYLIST = gql`
  mutation UpdateAudioDevicePlaylistMutation(
    $deviceId: String!
    $devicePlayType: _devicePlayType!
    $devicePlayTypeId: String!
  ) {
    updateAudioDevicePlaylist(
      deviceId: $deviceId
      devicePlayType: $devicePlayType
      devicePlayTypeId: $devicePlayTypeId
    ) {
      id
      currentPlaylistId
      currentSceneId
    }
  }
`;
