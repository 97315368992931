import React, { useMemo } from 'react';
import * as rdd from 'react-device-detect';

import LoadingProvider from 'components/loading-provider/loading-provider';
import useAllScenes from '../hooks/use-all-scenes';
import CardLink from 'components/card-link/card-link';

import useAuth from '../../auth/hooks/use-auth';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import Button from '../../../components/button/button';
import { stringSizeLimiter } from '../../../utils/string-size-limiter';

const WhatDoYouDo = () => {
  let { id } = useParams();
  let history = useHistory();
  const { previewUser, user } = useAuth();
  const path = previewUser ? `user/${id}` : id;
  const { allFeaturedScenes, loading: loadingScenes } = useAllScenes();

  const clientSubCategory = useMemo(
    () => user?.musicProfile?.subCategory || '',
    [allFeaturedScenes, user?.musicProfile?.subCategory]
  );

  const scenesBySubCategories = useMemo(
    () =>
      allFeaturedScenes?.filter(
        (item) =>
          item.newCategory?.filter(
            (item2) =>
              clientSubCategory === 'Other' ||
              item2?.subCategories?.includes(clientSubCategory)
          )?.length > 0
      ),
    [allFeaturedScenes, clientSubCategory]
  );

  const scenesBySubCategoriesSample = useMemo(
    () => _.sampleSize(scenesBySubCategories, 8),
    [scenesBySubCategories]
  );

  const categoryString = `${user?.musicProfile?.category} / ${user?.musicProfile?.subCategory}`;
  const categoryStringWLimit = stringSizeLimiter(categoryString, 33);

  return (
    <>
      {rdd.isMobileOnly ? (
        <div style={{ marginBottom: 39 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 20,
              padding: '0px 13px',
            }}
          >
            <h1 style={{ fontSize: 12, fontWeight: 'normal' }}>
              What do you do?&nbsp;
            </h1>
            <h2 style={{ flex: 'auto', fontSize: 12 }}>
              {categoryStringWLimit}
            </h2>
            <h1
              style={{ fontSize: 12, color: '#3BABA1', fontWeight: 'normal' }}
              onClick={() =>
                history.push(`/${path}/generated-content/what-do-you-do`)
              }
            >
              View all
            </h1>
          </div>

          <div
            style={{
              overflow: 'auto',
              whiteSpace: 'nowrap',
              display: 'flex',
            }}
          >
            {scenesBySubCategoriesSample?.map((item, index) => {
              const to =
                item.type === 'playlist'
                  ? `/${path}/search/playlists/${item.id}`
                  : `/${path}/search/all/scene/${item.id}`;
              return (
                <CardLink
                  key={item.id}
                  handleClick={() => null}
                  to={to}
                  title={item.name}
                  cover={item.cover}
                  style={{
                    height: 225,
                    width: 225,
                    display: 'inline-block',
                    marginLeft: 13,
                    marginRight:
                      index === scenesBySubCategoriesSample.length - 1 ? 13 : 7,
                  }}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div style={{ top: -38, zIndex: 5 }}>
          <h5>What do you do?</h5>
          <div style={{ display: 'flex' }}>
            <h2 style={{ flex: 'auto' }}>{categoryString}</h2>
            <Button
              onClick={() =>
                (window.location.href = `/${path}/generated-content/what-do-you-do`)
              }
              modifier="as-link"
            >
              View all
            </Button>
          </div>
          <LoadingProvider loading={loadingScenes}>
            <h4
              style={{
                marginBottom: 20,
                fontSize: 16,
                ...(rdd.isMobileOnly && { marginLeft: 20 }),
              }}
            />
            <div
              className="list-results"
              style={{
                justifyContent: 'space-between',
                marginBottom: 20,
              }}
            >
              {_.sampleSize(scenesBySubCategories, 8)?.map((item) => {
                const to =
                  item.type === 'playlist'
                    ? `/${path}/search/playlists/${item.id}`
                    : `/${path}/search/all/scene/${item.id}`;
                return (
                  <>
                    <CardLink
                      key={item.id}
                      to={to}
                      title={item.name}
                      cover={item.cover}
                    />
                  </>
                );
              })}
            </div>
          </LoadingProvider>
        </div>
      )}
    </>
  );
};

export default WhatDoYouDo;
