/**
 * External dependencies
 */
import React, { useEffect, useState } from 'react';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import LoadingProvider from 'components/loading-provider/loading-provider';

const fetchHighlights = async () => {
  const response = await fetch(process.env.REACT_APP_WORDPRESS_POSTS);
  return response.json();
};

const HighlightPage = ({
  match: {
    params: { highlight },
  },
}) => {
  const [currentHighlight, setCurrentHighlight] = useState();

  useEffect(() => {
    fetchHighlights().then((res) => {
      const highlightToSet = res.find((item) => item.slug.includes(highlight));
      setCurrentHighlight(highlightToSet);
    });
  }, []);

  return (
    <DashboardLayout
      subtitle="Highlights"
      title={currentHighlight && currentHighlight.title.rendered}
      backgroundImage={`url(${
        require('assets/svg-icons/background-OR.svg').default
      })`}
      backgroundColor={
        currentHighlight && currentHighlight.acf.background_color
      }
      modifier="highlights"
    >
      <LoadingProvider loading={!currentHighlight}>
        <div className="highlight">
          {currentHighlight?._embedded['wp:featuredmedia'] && <div className="highlight__image">
            <img
              src={
                currentHighlight._embedded['wp:featuredmedia'][0].media_details
                  .sizes?.full?.source_url
              }
              alt='How to design a perfect playlist'
            />
          </div>}

          <div
            className="highlight__entry"
            dangerouslySetInnerHTML={{
              __html: currentHighlight && currentHighlight.content.rendered,
            }}
          ></div>
        </div>
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default HighlightPage;
