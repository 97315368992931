import React from 'react';

import { ReactComponent as Image } from '../../assets/svg-icons/login-graphic.svg';

import Button from '../button/button';
import { useHistory } from 'react-router-dom';
import useAuth from '../../domain/auth/hooks/use-auth';
import toTitleCase from '../../utils/toTitleCase';

const PreviewWelcomeCard = ({ pathname }) => {
  const { user } = useAuth();
  const history = useHistory();

  // make all first letter in user's name uppercase
  const parsedName = toTitleCase(user?.profile?.name);

  return (
    <div
      style={{
        width: '100%',
        height: 300,
        backgroundColor: '#20349F',
        borderRadius: 13,
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <div
        style={{
          flex: 1,
          padding: 25,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <h1
          style={{ fontSize: 23, width: 260, cursor: 'pointer' }}
          onClick={() => null}
        >
          Welcome to the world of Activaire Curator
        </h1>
        <p style={{ fontSize: 13 }}>
          Hi <b>{parsedName}</b> 👋
          <br /> Thanks for answering our questionnaire. Based on your
          responses, We’ve curated a selection of Playlists and Scenes just for
          you. Click the button below to start exploring. If you’d like to
          browse our full catalog of Playlists and Scenes, scroll down and visit
          the Scenes, Genres, Intensities, and Vibes pages.
        </p>
        <Button
          modifier="primary-dense"
          style={{ width: 173 }}
          onClick={() => history.push(`${pathname}/generated-content`)}
        >
          My Playlists and Scenes
        </Button>
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image style={{ height: '70%' }} />
      </div>
    </div>
  );
};

export default PreviewWelcomeCard;
