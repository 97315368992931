/**
 * External dependencies
 */
import React from 'react';
import { ApolloProvider } from '@apollo/client';

/**
 * Internal dependencies
 */
import apolloClient from 'utils/apollo-client/apollo-client';
import useAuth from 'domain/auth/hooks/use-auth';

const ApolloClientProvider = ({ children }) => {
  const { previewToken } = useAuth();
  return (
    <ApolloProvider client={apolloClient(previewToken)}>
      {children}
    </ApolloProvider>
  );
};

export default ApolloClientProvider;
