/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';
import { GENERATE_MUSIC_LIST } from '../mutations/generate-music-list';

/**
 * Internal dependencies
 */

export default function useGenerateMusicList() {
  const [generateMusicList, { loading }] = useMutation(GENERATE_MUSIC_LIST);

  return {
    generateMusicList,
    loading,
  };
}
