/**
 * External dependencies
 */
import React, { useState, useMemo } from 'react';
import axios from 'axios';

/**
 * Internal dependencies
 */
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';
import useLocalStorage from 'utils/use-local-storage';
const AuthProvider = ({ children }) => {
  const [sessionToken, setSessionToken, removeSessionToken] = useLocalStorage(
    'sessionToken',
    null
  );
  const [sessionProvider, setSessionProvider, removeSessionProvider] =
    useLocalStorage('sessionProvider', null);
  const [currentDevice, setCurrentDevice, removeCurrentDevice] =
    useLocalStorage('currentDevice', null);

  const [currentClient, setCurrentClient, removeCurrentClient] =
    useLocalStorage('currentClient', null);

  const [previewToken, setPreviewToken, removeCurrentPreviewToken] =
    useLocalStorage('previewToken', null);

  const [previewId, setPreviewId, removeCurrentPreviewId] = useLocalStorage(
    'previewId',
    null
  );

  const [welcomeModal, setWelcomeModal, removeWelcomeModal] = useLocalStorage(
    'welcomeModal',
    true
  );

  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [sonosFlow, setSonosFlow] = useState(false);
  const [viewableCategories, setViewableCategories] = useState(null);
  // mark as true if the path is /user/:id in the routes file
  const [previewUser, setPreviewUser] = useState(false);

  const isLoggedIn = useMemo(() => false, []);

  const loginSuccess = ({
    token,
    user,
    provider,
    sonosAuthObj,
    redirectUrl,
    linkCode,
  }) => {
    setUser(user);
    setPreviewToken(token);
    setSessionProvider(provider);
    if (
      sonosAuthObj?.authToken &&
      sonosAuthObj?.message === 'OK' &&
      redirectUrl &&
      linkCode
    ) {
      window.location.href = `${redirectUrl}?linkCode=${linkCode}&authToken=${sonosAuthObj.authToken}&sonosTrial=activate`;
    }
    setLoading(false);
  };

  const logoutSuccess = () => {
    removeSessionToken();
    removeSessionProvider();
    removeCurrentDevice();
    removeCurrentClient();
    setUser(null);
  };

  const setLoginLoading = (payload) => {
    setLoading(payload);
  };

  const newUserRegisteredHubspotEmail = async ({
    firstname,
    email,
    company,
    servicetype,
    loginmethod,
    householdId,
  }) => {
    try {
      const fields = [
        {
          name: 'firstname',
          value: firstname,
        },
        {
          name: 'email',
          value: email,
        },
        {
          name: 'company',
          value: company,
        },
        {
          name: 'loginmethod',
          value: loginmethod,
        },
      ];
      if (householdId) {
        fields.push({
          name: 'sonos_device',
          value: householdId,
        });
      }
      const data = {
        fields,
      };

      if (servicetype.length > 0) {
        servicetype.forEach((item) => {
          data.fields.push({
            name: 'servicetype',
            value: item,
          });
        });
      }

      const config = {
        method: 'post',
        url: 'https://api.hsforms.com/submissions/v3/integration/submit/2621188/d79e8dd1-f416-4923-9af0-efed750b7452',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(data),
      };

      const response = await axios(config);
      return response;
    } catch (e) {
      const errorObj = { ...e };
      return (
        (errorObj?.response?.data?.errors &&
          errorObj?.response?.data?.errors[0]?.errorType) ||
        'OTHER_ERROR'
      );
    }
  };

  return (
    <AuthContext.Provider
      value={{
        token: sessionToken,
        user,
        setUser,
        sessionProvider,
        currentDevice,
        setCurrentDevice,
        currentClient,
        setCurrentClient,
        isLoggedIn,
        loginSuccess,
        logoutSuccess,
        setLoginLoading,
        setSessionToken,
        setSessionProvider,
        isLoading,
        newUserRegisteredHubspotEmail,
        sonosFlow,
        setSonosFlow,
        previewToken,
        setPreviewToken,
        removeCurrentPreviewToken,
        previewId,
        setPreviewId,
        removeCurrentPreviewId,
        welcomeModal,
        setWelcomeModal,
        removeWelcomeModal,
        viewableCategories,
        setViewableCategories,
        previewUser,
        setPreviewUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
