import React, { useContext, useEffect } from 'react';
import BoardingWrapper from '../boarding-wrapper';
import Tag from './category/tag';
import { BoardingContext } from '../context/boardingContext';
import InputBoarding from './input-boarding';

const CategorySpecific = ({ categories }) => {
  const {
    selectedSpecificCategory,
    setSelectedSpecificCategory,
    selectedCategory,
    otherSpecificCategoryValue,
    setOtherSpecificCategoryValue,
    companyName,
    otherCategoryValue,
  } = useContext(BoardingContext);

  const findCategory = categories.find(
    (item) => item.name === selectedCategory
  );
  const subCategories = [...(findCategory?.subCategories || [])];

  selectedCategory !== 'Other' && subCategories.push('Other');

  useEffect(() => {
    if (otherSpecificCategoryValue?.length) {
      setSelectedSpecificCategory('Other');
    }
  }, [otherSpecificCategoryValue]);

  const preferredOrder = [
    'Fashion',
    'Footwear',
    'Frames & Eyewear',
    'Accessories',
    'Athletic Apparel',
    'Home Goods',
    'Boutique',
    'Streetwear',
    'Flagship',
    'Trendy',
    'Luxury',
    'Outlet',
  ];

  const sortedList =
    selectedCategory === 'RETAIL'
      ? preferredOrder.concat(
          subCategories.filter((item) => !preferredOrder.includes(item))
        )
      : subCategories;

  return (
    <BoardingWrapper
      disabled={
        !selectedSpecificCategory ||
        (selectedSpecificCategory === 'Other' &&
          otherSpecificCategoryValue.length < 2)
      }
      progress={{ firstBar: '100', secondBar: '33', thirdBar: '0' }}
    >
      <div className="modal-boarding">
        <p className="modal-boarding__subtitle">{companyName}</p>
        <p className="modal-boarding__title">More specifically?</p>
        <Tag
          title={
            selectedCategory !== 'Other' ? selectedCategory : otherCategoryValue
          }
          isSelected={true}
          setSelectedCategory={() => {}}
          style={{ width: 'fit-content', marginBottom: 25 }}
        />

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
          }}
        >
          {sortedList.map((item) => (
            <Tag
              key={item}
              title={item}
              isSelected={selectedSpecificCategory === item}
              handleOnClick={() => {
                setSelectedSpecificCategory(item);
                if (item !== 'Other') {
                  setOtherSpecificCategoryValue('');
                }
              }}
            />
          ))}
        </div>
        {selectedSpecificCategory === 'Other' && (
          <InputBoarding
            onChange={(e) => setOtherSpecificCategoryValue(e.target.value)}
            value={otherSpecificCategoryValue}
            placeholder="Your business type"
            style={{ marginTop: 10 }}
          />
        )}
      </div>
    </BoardingWrapper>
  );
};

export default CategorySpecific;
