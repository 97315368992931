import React, { useContext } from 'react';
import BoardingWrapper from '../boarding-wrapper';
import { BoardingContext } from '../context/boardingContext';
import Tag from './category/tag';

const Vibe = ({ vibes }) => {
  const { companyName, vibeValues, handleVibes } = useContext(BoardingContext);
  return (
    <BoardingWrapper
      disabled={!vibeValues.length}
      progress={{ firstBar: '100', secondBar: '100', thirdBar: '66' }}
    >
      <div className="modal-boarding">
        <p className="modal-boarding__subtitle">{companyName}</p>
        <p
          className="modal-boarding__title"
          style={{ marginBottom: 0, paddingBottom: 4 }}
        >
          What’s your vibe?
        </p>
        <p className="modal-boarding__subtitle">
          How does your brand make your customers feel?
        </p>
        <p className="modal-boarding__info">Select one or more</p>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
          }}
        >
          {vibes.map((item) => (
            <Tag
              key={item?.name}
              title={item?.name}
              isSelected={vibeValues.includes(item?.name)}
              handleOnClick={() => handleVibes(item?.name)}
            />
          ))}
        </div>
      </div>
    </BoardingWrapper>
  );
};

export default Vibe;
