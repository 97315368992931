import 'styles/style.scss';

/**
 * External dependencies
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const history = createBrowserHistory();
/**
 * Internal dependencies
 */
import App from 'blocks/app/app';
import ApolloClientProvider from 'utils/apollo-client/apollo-client-provider';
import AuthProvider from 'domain/auth/contexts/auth-context/auth-provider';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

Bugsnag.start({
  apiKey: '09dd3f6c539b606931bd5f111ac2573e',
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <AuthProvider>
      <ApolloClientProvider>
        <Router history={history}>
          <App />
          <ToastContainer closeOnClick={false} transition={Slide} />
        </Router>
      </ApolloClientProvider>
    </AuthProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);
