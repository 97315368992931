/**
 * External dependencies
 */
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import CardLinks from 'components/card-links/card-links';
import CardLink from 'components/card-link/card-link';
import Field from 'components/field/field';
import SearchSvg from '../../../assets/svgComponents/SearchSvg';
import LoadingProvider from '../../../components/loading-provider/loading-provider';
import Page404 from 'views/404/404';

import SearchSection from '../../../components/search-section/search-section';
import useAllPlaylists from '../hooks/use-all-playlists';
import useAllScenes from '../hooks/use-all-scenes';
import _ from 'lodash';

import WelcomeModal from 'domain/music/blocks/welcome-modal';

import AuthContext from 'domain/auth/contexts/auth-context/auth-context';

import * as rdd from 'react-device-detect';

const MusicPage = ({ location: { pathname } }) => {
  const { previewToken, welcomeModal, setWelcomeModal } =
    useContext(AuthContext);
  const history = useHistory();
  const [searchValue, setSearchValue] = useState();
  const [featuredPlayables, setFeaturedPlayables] = useState([]);
  const [openedModal, setOpenedModal] = useState(welcomeModal);

  const { allFeaturedPlaylists, loading: loadingPlaylists } = useAllPlaylists();
  const { allFeaturedScenes, loading: loadingScenes } = useAllScenes();

  useEffect(() => {
    setWelcomeModal(false);
  }, []);

  useEffect(() => {
    if (!loadingScenes && !loadingPlaylists) {
      const featuredConcat = [...allFeaturedScenes, ...allFeaturedPlaylists];
      setFeaturedPlayables(_.sampleSize(featuredConcat, 4));
    }
  }, [loadingScenes, loadingPlaylists]);
  const onNavigate = (e) => {
    e.stopPropagation();
    history.push(`${pathname}/search/all/featured`);
  };

  if (previewToken === 'ACCESS DENIED') {
    return <Page404 />;
  }

  if (previewToken === 'TOKEN EXPIRED') {
    return (
      <Page404
        message="This preview link has expired, please contact us for a new link at support@activaire.com."
        errorNumber={403}
      />
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!searchValue) {
      return;
    }

    history.push(`${pathname}/search/${searchValue}`);
  };

  const handleOnCloseModal = () => {
    setOpenedModal(false);
  };

  return (
    <LoadingProvider
      loading={!previewToken || loadingScenes || loadingPlaylists}
    >
      <WelcomeModal
        visible={openedModal}
        onClose={() => handleOnCloseModal()}
      />
      <DashboardLayout
        isOnMusicRoute
        title="Music"
        backgroundImage={`url(${
          require('assets/svg-icons/background-OR.svg').default
        })`}
        modifier="music"
      >
        <div className="music-search">
          {rdd.isMobileOnly && (
            <form
              action="?"
              onSubmit={handleSubmit}
              className="page__head"
              style={{
                display: 'inherit',
                paddingTop: 0,
              }}
            >
              <Field className="music__button" type="submit" value="Search" />
              <Field
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                className="music-field"
                type="search"
                icon={<SearchSvg />}
                style={{ borderColor: '#393B5A' }}
              />
            </form>
          )}
          {!rdd.isMobileOnly && featuredPlayables.length > 0 && (
            <SearchSection
              title={'Featured Playlists and Scenes'}
              collectionId="scenes"
              hasViewAll={true}
              to={`${pathname}/search/all/featured`}
            >
              <div
                style={{
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(4, 1fr)',
                  gridGap: 20,
                  marginBottom: 20,
                }}
              >
                {featuredPlayables?.map((item) => {
                  const to =
                    item.type === 'playlist'
                      ? `${pathname}/search/playlists/${item?.id}`
                      : `${pathname}/search/all/scene/${item?.id}`;
                  return (
                    <CardLink
                      key={item?.id}
                      handleClick={() => null}
                      to={to}
                      title={item?.name}
                      cover={item?.cover}
                    />
                  );
                })}
              </div>
            </SearchSection>
          )}

          {rdd.isMobileOnly && featuredPlayables.length > 0 && (
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 20,
                  padding: '0px 13px',
                }}
              >
                <h1 style={{ fontSize: 16 }}>Featured Playlists and Scenes</h1>
                <h1
                  style={{ fontSize: 16, color: '#3BABA1' }}
                  onClick={onNavigate}
                >
                  View all
                </h1>
              </div>

              <div
                style={{
                  overflow: 'auto',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                }}
              >
                {featuredPlayables?.map((item, index) => {
                  const to =
                    item.type === 'playlist'
                      ? `${pathname}/search/playlists/${item.id}`
                      : `${pathname}/search/all/scene/${item.id}`;
                  return (
                    <CardLink
                      key={item.id}
                      handleClick={() => null}
                      to={to}
                      title={item.name}
                      cover={item.cover}
                      style={{
                        height: 225,
                        width: 225,
                        display: 'inline-block',
                        marginLeft: 13,
                        marginRight:
                          index === featuredPlayables.length - 1 ? 13 : 7,
                      }}
                    />
                  );
                })}
              </div>
            </div>
          )}

          {/* {!rdd.isMobileOnly && (
            <form
              action="?"
              onSubmit={handleSubmit}
              className="page__head"
              style={{
                width: 980,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Field className="music__button" type="submit" value="Search" />
              <Field
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                className="music-field"
                type="search"
                icon={<SearchSvg />}
                style={{ borderColor: '#393B5A' }}
              />
            </form>
          )} */}

          <h4
            style={{
              fontSize: 16,
              marginBottom: rdd.isMobileOnly ? 7 : 20,
              marginLeft: rdd.isMobileOnly ? 13 : 0,
              marginTop: rdd.isMobileOnly ? 68 : 40,
            }}
          >
            Discover Playlists and Scenes
          </h4>

          <CardLinks large>
            <CardLink
              to={`${pathname}/search/all/scenes`}
              title="Scenes"
              description="Expertly curated Scenes for every business, from restaurants, shops, and hotels, to offices, schools, and fitness centers."
              color="#C361E9"
              graphic={require('assets/images/card-image-1.png').default}
              graphicAlign="right bottom"
              customStyle={{ mixBlendMode: 'overlay' }}
            />

            <CardLink
              to={`${pathname}/search/all/genres`}
              title="Genres"
              description="Discover new and classic tracks sorted by the genre. Rock out, get jazzy, or add a splash of Pop whenever the mood strikes."
              color="#670BCE"
              graphic={require('assets/images/card-image-2.png').default}
              graphicAlign="center bottom"
            />

            <CardLink
              to={`${pathname}/search/all/intensities`}
              title="Intensities"
              description="Set the perfect pace ranging from easy and smooth to upbeat and eclectic."
              color="#0BCEC9"
              graphic={require('assets/images/card-image-3.png').default}
              graphicAlign="right top"
            />

            <CardLink
              to={`${pathname}/search/all/vibe`}
              title="Vibes"
              description="Find the vibe that resonates with your crowd. Pick things and get energetic or mellow out with a wash of calm tunes."
              color="#6183E9"
              graphic={require('assets/images/card-image-4.png').default}
              graphicAlign="center bottom"
            />
          </CardLinks>
        </div>
      </DashboardLayout>
    </LoadingProvider>
  );
};

export default MusicPage;
