/**
 * External dependencies
 */
import React, { useEffect, useContext, useMemo } from 'react';
import { Switch, Route, useParams, useHistory } from 'react-router-dom';

/**
 * Internal dependencies
 */
import MusicPage from 'domain/music/pages/music';
import MusicSearchCollection from 'domain/music/pages/music-search-all';
import MusicSearchPage from 'domain/music/pages/music-search';
import CurrentPlaylistPage from 'domain/music/pages/current-playlist';
import CuratedMixPage from 'domain/music/pages/curated-mix';
import SingleGenrePage from 'domain/music/pages/single-genre';
import SingleScenePage from 'domain/music/pages/single-scene';
import SingleIntensityPage from 'domain/music/pages/single-intensity';
import SceneSubCategoryPage from 'domain/music/pages/subcategory-page';
import SceneSubCategories from 'domain/music/pages/scene-subcategories';
import FeaturedPage from 'domain/music/pages/featured-page';
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';
import usePreviewToken from '../../music/hooks/use-preview-token';
import useViewables from '../hooks/use-viewables';
import SingleVibePage from '../pages/single-vibe';

const MusicRoutes = () => {
  const {
    setPreviewToken,
    previewId: savedPreviewId,
    setPreviewId,
    viewableCategories,
    setViewableCategories,
  } = useContext(AuthContext);
  const { id } = useParams();
  const history = useHistory();

  const { previewToken } = usePreviewToken(id.toLowerCase());
  const { viewables } = useViewables(id.toLowerCase());

  useEffect(() => {
    if (!viewableCategories && viewables) {
      setViewableCategories(viewables);
    }
    if (
      previewToken &&
      viewables &&
      previewToken !== 'ACCESS DENIED' &&
      previewToken !== 'TOKEN EXPIRED' &&
      savedPreviewId !== id
    ) {
      setPreviewToken(previewToken);
      setPreviewId(id);
    }

    if (previewToken === 'ACCESS DENIED' || previewToken === 'TOKEN EXPIRED') {
      setPreviewToken(previewToken);
      setPreviewId(null);
      setViewableCategories([]);
      history.push('/');
    }
  }, [previewToken, viewables]);

  return (
    <Switch>
      <Route exact path="/:id" component={MusicPage} />

      <Route exact path="/:id/curated-mix" component={CuratedMixPage} />

      <Route exact path="/:id/search/:searchTerm" component={MusicSearchPage} />

      <Route exact path="/:id/search/all/featured" component={FeaturedPage} />

      <Route
        exact
        path="/:id/search/all/:collectionId"
        component={MusicSearchCollection}
      />

      <Route
        exact
        path="/:id/search/:collectionId/:playlistId"
        component={CurrentPlaylistPage}
      />

      <Route
        exact
        path="/:id/search/all/genres/:genre"
        component={SingleGenrePage}
      />

      <Route
        exact
        path="/:id/search/all/vibe/:vibe"
        component={SingleVibePage}
      />

      <Route
        exact
        path="/:id/search/all/scenes/:category/:subcategory"
        component={SceneSubCategoryPage}
      />

      <Route
        exact
        path="/:id/search/all/scene/:sceneId"
        component={SingleScenePage}
      />

      <Route
        exact
        path="/:id/search/all/scenes/:category"
        component={SceneSubCategories}
      />

      <Route
        exact
        path="/:id/search/all/intensities/:intensity"
        component={SingleIntensityPage}
      />
    </Switch>
  );
};

export default MusicRoutes;
