import React, { useState, useContext } from 'react';
import * as rdd from 'react-device-detect';

import LoadingProvider from 'components/loading-provider/loading-provider';
import SearchSection from 'components/search-section/search-section';
import _ from 'lodash';
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';
import getRandomColor from '../../../helpers/get-random-color';
import capitalize from '../../../helpers/capitalize';
import useAllScenes from '../hooks/use-all-scenes';
import ResultItem from 'components/result-item/result-item';
import flattenArrays from '../../../utils/flattenArrays';
import Card from '../components/card';

const AllSceneCategories = ({ deviceId }) => {
  const [categoryExpanded, setCategoryExpanded] = useState([]);
  const { allScenes, allFeaturedScenes } = useAllScenes();
  const { viewableCategories, previewUser } = useContext(AuthContext);
  const categories = [...(viewableCategories || [])]
    ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
    .filter((item) => item.subCategories.length > 0);

  const path = previewUser ? `user/${deviceId}` : deviceId;

  const handleViewAll = (category) => {
    setCategoryExpanded([...new Set([...categoryExpanded, category])]);
  };

  return (
    <LoadingProvider loading={!viewableCategories}>
      {rdd?.isMobileOnly && (
        <h4 style={{ fontSize: 13, padding: '0px 0px 15px 13px' }}>
          Categories
        </h4>
      )}
      <div
        style={
          rdd?.isMobileOnly
            ? {
                width: '100vw',
                marginBottom: 135,
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridGap: 13,
                padding: '0px 13px',
              }
            : {}
        }
      >
        {categories?.map((item, index) => {
          const category = item?.name;
          const subCategoriesPermissions = [
            ...(item?.subCategories || []),
          ].sort();
          const filteredScenes = [...allScenes, ...allFeaturedScenes]?.filter(
            (scene) => {
              return (
                scene.newCategory.findIndex(
                  (item2) =>
                    item2.category === category &&
                    _.intersection(
                      subCategoriesPermissions,
                      item2.subCategories
                    ).length > 0
                ) >= 0
              );
            }
          );

          const subCategoriesArray = flattenArrays(
            filteredScenes?.map((scene) => scene.newCategory)
          );

          const subCategoriesFromScenes =
            _.uniq(
              flattenArrays(
                subCategoriesArray
                  ?.filter((item) => item.category === category)
                  .map((item) => item.subCategories)
              )
            ) || [];

          const subCategories = _.intersection(
            subCategoriesPermissions,
            subCategoriesFromScenes
          );

          if (filteredScenes.length === 0) {
            return null;
          }

          return rdd?.isMobileOnly ? (
            <Card
              key={category}
              index={index}
              title={category}
              to={`/${path}/search/all/scenes/${category.toLowerCase()}`}
            />
          ) : (
            // DESKTOP VERSION BELOW
            // DESKTOP VERSION
            // DESKTOP VERSION
            // DESKTOP VERSION

            <SearchSection
              title={capitalize(category.toLowerCase())}
              deviceId={deviceId}
              collectionId="scenes"
              hasViewAll={!categoryExpanded.includes(category)}
              to={`/${path}/search/all/scenes/${category.toLowerCase()}`}
              key={category}
              textStyle={{ fontSize: 22 }}
              count={subCategories.length}
              isInSearchSection
              onClick={() => handleViewAll(category)}
            >
              <div
                style={{
                  maxWidth: 980,
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gridGap: 20,
                }}
              >
                {subCategories
                  .slice(
                    0,
                    categoryExpanded.includes(category)
                      ? subCategories.length
                      : 6
                  )
                  .map((item) => {
                    return (
                      <ResultItem
                        dotColor={getRandomColor(_.random(14))}
                        key={item}
                        to={`/${path}/search/all/scenes/${category.toLowerCase()}/${item.toLowerCase()}`}
                        title={capitalize(item.toLowerCase())}
                      />
                    );
                  })}
              </div>
            </SearchSection>
          );
        })}
      </div>
    </LoadingProvider>
  );
};

export default AllSceneCategories;
