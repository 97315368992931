/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const ALL_AUDIO_DEVICES = gql`
  query allAudioDevicesFavouritesQuery(
    $allAudioDevicesFavouritesFirst: Int
    $allAudioDevicesFavouritesAfter: String
    $allAudioDevicesFavouritesFilter: AudioDeviceFilter
    $allAudioDevicesFavouritesSort: AudioDeviceSort
    $userId: String
    $deviceId: String
  ) {
    viewer {
      allAudioDevicesFavourites(
        first: $allAudioDevicesFavouritesFirst
        after: $allAudioDevicesFavouritesAfter
        filter: $allAudioDevicesFavouritesFilter
        sort: $allAudioDevicesFavouritesSort
        userId: $userId
      ) {
        edges {
          cursor
          node {
            id
            name
            description
            lastOnline
            appUpTime
            clientId
            currentPlaylistId
            currentPlaylist {
              name
              cover
            }
            remotePlaylistMode
            currentSceneId
            isPlaying
            isOnline
            latitude
            longitude
            pausedStatus
            skipDisabled
            scheduleIds
            internalIpAddress
            manuallyPaused
            publicIpAddress
            macAddress
            ethernetMacAddress
            makeAndModel
            skipCount
            skipDisabled
            volume
            remoteVolume
            appStatus {
              message
              status
              description
              time
            }
            usersId {
              id
              profile {
                name
              }
              emails {
                address
              }
              roles
              rolesConsole {
                roleAccess
                roleAudio
              }
              services {
                google {
                  idToken
                }
                facebook {
                  id
                  link
                }
              }
              status {
                lastLogin {
                  date
                  userAgent
                }
                online
              }
              favouriteDevices
            }
            allSchedule {
              id
              name
              injectable
              startDate
              endDate
              size
              clientId
              playingPlaylists {
                playlistId
                id
                name
                volume
                startTime
                endTime
              }
            }
            currentSongId
            currentSongData(userId: $userId, deviceId: $deviceId) {
              node {
                title
                album
                artist
                artworkUrl
                streaming {
                  url
                  filename
                  size
                }
              }
              songReactions {
                userName
                songReaction
              }
              songReaction {
                songReaction
                feedback
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        count
      }
    }
  }
`;
