/**
 * External dependencies
 */
import React from 'react';
import stc from 'string-to-color';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import CardLink from 'components/card-link/card-link';
import useAllPlaylists from '../hooks/use-all-playlists';
import LoadingProvider from 'components/loading-provider/loading-provider';
import * as rdd from 'react-device-detect';
import MusicListItem from '../components/music-list-item';
import useAuth from '../../auth/hooks/use-auth';

const SingleGenrePage = ({
  match: {
    params: { id, genre },
  },
}) => {
  const { previewUser } = useAuth();
  const path = previewUser ? `user/${id}` : id;
  const { allPlaylists } = useAllPlaylists({ deviceId: id });

  const allMixes = allPlaylists?.filter((item) => {
    const genreUpperCase = item.genre?.map((item) => item.toUpperCase());
    return genreUpperCase.includes(genre);
  });

  return (
    <DashboardLayout isOnMusicRoute title={genre} modifier="current-playlist">
      <LoadingProvider loading={!allMixes}>
        {rdd?.isMobileOnly ? (
          <div style={{ marginBottom: 135 }}>
            <h4 style={{ fontSize: 13, padding: '0px 0px 15px 13px' }}>
              {allMixes.length} Playlists Available
            </h4>

            {allMixes?.map((item) => {
              return (
                <MusicListItem
                  key={item.id}
                  to={`/${path}/search/playlists/${item.id}`}
                  cover={item.cover}
                  name={item.name}
                  description={item.description}
                />
              );
            })}
          </div>
        ) : (
          <div
            style={{
              maxWidth: 980,
              width: 'auto',
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gridGap: 20,
              marginBottom: 35,
            }}
          >
            {allMixes?.map((playlist) => (
              <CardLink
                triggerPlayNow={{
                  playlistId: playlist?.id,
                  devicePlayType: 'PLAYLIST',
                }}
                key={playlist?.id}
                to={`/${path}/search/playlists/${playlist.id}`}
                cover={playlist?.cover}
                title={playlist?.name}
                color={stc(playlist?.name)}
                style={{ margin: 0 }}
              />
            ))}
          </div>
        )}
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default SingleGenrePage;
