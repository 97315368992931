/**
 * External dependencies
 */
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { USER } from 'domain/auth/queries/user';

export default function useUser() {
  const { data, loading, error } = useQuery(USER);

  return {
    data: data && data.viewer.user,
    loading,
    error,
  };
}
