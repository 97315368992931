/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';
import { USER_PREVIEW_ACTION } from '../mutations/user-preview-action';

/**
 * Internal dependencies
 */

export default function useUserPreviewAction() {
  const [userPreviewAction, { loading, data }] =
    useMutation(USER_PREVIEW_ACTION);

  return {
    userPreviewAction,
    loading,
    data,
  };
}
