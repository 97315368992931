import React, { useEffect, useState } from 'react';
import * as rdd from 'react-device-detect';

import DashboardLayout from 'layouts/dashboard-layout';

import LoadingProvider from 'components/loading-provider/loading-provider';
import useAllScenes from '../hooks/use-all-scenes';
import useAllPlaylists from '../hooks/use-all-playlists';
import CardLink from 'components/card-link/card-link';

import ResultItem from '../../../components/result-item/result-item';
import stc from 'string-to-color';
import useAuth from '../../auth/hooks/use-auth';

const FeaturedPage = ({
  match: {
    params: { id },
  },
}) => {
  const { previewUser } = useAuth();
  const path = previewUser ? `user/${id}` : id;
  const [featuredPlayables, setFeaturedPlayables] = useState([]);
  const { allFeaturedScenes, loading: loadingScenes } = useAllScenes();
  const { allFeaturedPlaylists, loading: loadingPlaylists } = useAllPlaylists();

  useEffect(() => {
    const [allFeaturedScenesIds, allFeaturedPlaylistsIds] = [
      allFeaturedScenes.map((item) => item.id) || [],
      allFeaturedPlaylists.map((item) => item.id) || [],
    ];
    const featuredPlayableIds = allFeaturedScenesIds.concat(
      allFeaturedPlaylistsIds
    );
    if (
      allFeaturedScenes &&
      allFeaturedPlaylists &&
      featuredPlayableIds.length > 0
    ) {
      const featuredConcat = [
        ...allFeaturedScenes,
        ...allFeaturedPlaylists,
      ].sort((a, b) => {
        const [nameA, nameB] = [a?.name?.toLowerCase(), b?.name?.toLowerCase()];
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setFeaturedPlayables(featuredConcat);
    }
  }, [allFeaturedScenes, allFeaturedPlaylists]);
  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle="Music"
      title="Featured Playlists and Scenes"
      modifier="music-search"
    >
      <LoadingProvider loading={loadingScenes || loadingPlaylists}>
        <h4
          style={{
            marginBottom: 20,
            fontSize: 16,
            ...(rdd.isMobileOnly && { marginLeft: 20 }),
          }}
        />
        {rdd.isMobileOnly ? (
          <div className="list-results-mobile">
            {featuredPlayables?.map((item) => {
              const to =
                item.type === 'playlist'
                  ? `/${path}/search/playlists/${item.id}`
                  : `/${path}/search/all/scene/${item.id}`;
              return (
                <ResultItem
                  color={stc(item.id)}
                  image={item.cover}
                  key={item.id}
                  to={to}
                  title={item.name}
                  description={item.description}
                  hasImage
                  isMobile
                />
              );
            })}
          </div>
        ) : (
          <div
            className="list-results"
            style={{
              justifyContent: 'space-between',
              marginBottom: 20,
            }}
          >
            {featuredPlayables?.map((item) => {
              const to =
                item.type === 'playlist'
                  ? `/${path}/search/playlists/${item.id}`
                  : `/${path}/search/all/scene/${item.id}`;
              return (
                <CardLink
                  key={item.id}
                  to={to}
                  title={item.name}
                  cover={item.cover}
                />
              );
            })}
          </div>
        )}
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default FeaturedPage;
