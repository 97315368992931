/**
 * External dependencies
 */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import stc from 'string-to-color';
import * as rdd from 'react-device-detect';
import moment from 'moment';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import Playlist from 'blocks/playlist/playlist';
import PlaylistMobile from 'blocks/playlist/playlist-mobile';
import Page404 from 'views/404/404';
import useSinglePlaylist from '../hooks/use-single-playlist';
import getRandomArtwork from 'helpers/get-random-artwork';
// import useDeviceSubscription from '../../devices/hooks/use-device-subscription';
import { Howl } from 'howler';
import { SongItem } from './song-item';
import LoadingProvider from '../../../components/loading-provider/loading-provider';

const CurrentPlaylistPage = ({
  match: {
    params: { playlistId },
  },
}) => {
  const [songSkip, songLimit] = [0, 30];
  const { playlist, loading: playlistLoading } = useSinglePlaylist(
    playlistId,
    songSkip,
    songLimit
  );
  const [allSongs, setAllSongs] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [playingIndex, setPlayingIndex] = useState(-1);

  useEffect(() => {
    if (!playlistLoading) {
      setAllSongs(playlist?.allSongs || []);
    }
  }, [playlistLoading]);
  useEffect(() => {
    if (currentIndex > 0) {
      playPreview(currentIndex);
    }
  }, [currentIndex]);

  if (!playlistLoading && !playlist) {
    return <Page404 />;
  }

  let sound = window.Howler._howls[0] || null;

  const stopSound = () => {
    sound?.stop();
    sound?.unload();
    window.Howler.unload();
    sound = null;
  };

  const playPreview = (index) => {
    const song = allSongs[index];
    if (sound !== null && song.preview.url !== sound._src) {
      stopSound();
    }

    if (sound === null || !sound.playing()) {
      startNewHowl(index);
    }

    if (sound !== null && sound.playing()) {
      setPlayingIndex(-1);
      stopSound();
    }
  };

  const startNewHowl = (index) => {
    stopSound();
    const song = allSongs[index];
    sound = new Howl({
      src: [song?.preview?.url || ''],
      onplay: () => {
        setPlayingIndex(index);
      },
      onend: () => {
        stopSound();
        if (index < allSongs.length - 1) {
          startNewHowl(index + 1);
        } else {
          setCurrentIndex(index + 1);
        }
      },
      html5: true,
    });
    sound?.play();
  };

  return (
    <DashboardLayout
      subtitle={''}
      title={!playlistLoading ? playlist?.name : null}
      isSinglePlaylist={rdd.isMobileOnly && !!playlistId}
      playlistInfo={{
        deviceId: '',
        devicePlayType: 'PLAYLIST',
        devicePlayTypeId: playlistId,
      }}
      backgroundImage={`url(${
        require('assets/svg-icons/background-OR.svg').default
      })`}
      modifier="current-playlist"
    >
      <LoadingProvider
        loading={playlistLoading}
        style={{ position: 'fixed', top: '50%', left: '50%' }}
      >
        {rdd.isMobileOnly ? (
          <PlaylistMobile
            devicePlayType="PLAYLIST"
            playlistId={playlistId}
            title={!playlistLoading ? playlist.description : null}
            description={
              !playlistLoading ? playlist.featuredArtists.join(' - ') : null
            }
            handlePreview={() => playPreview(0)}
            color={stc(playlist?.name)}
            cover={
              !playlistLoading
                ? playlist.cover
                : require('assets/images/offline-artwork/offline3.png').default
            }
          >
            {!playlistLoading &&
              allSongs?.map((song, index) => (
                <SongItem
                  key={song.id}
                  isPlaying={playingIndex === index}
                  song={song}
                  onClick={() => playPreview(index)}
                  id={index}
                />
              ))}
          </PlaylistMobile>
        ) : (
          <Playlist
            devicePlayType="PLAYLIST"
            playlistId={playlistId}
            title={!playlistLoading ? playlist?.description : null}
            description={
              !playlistLoading ? playlist.featuredArtists.join(' - ') : null
            }
            color={stc(playlist?.name)}
            cover={
              !playlistLoading
                ? playlist.cover
                : require('assets/images/offline-artwork/offline3.png').default
            }
            showPreviewButton
            handlePreview={() => playPreview(0)}
          >
            {!playlistLoading &&
              allSongs?.map((song, index) => (
                <SongItem
                  key={song.id}
                  isPlaying={playingIndex === index}
                  song={song}
                  onClick={() => playPreview(index)}
                  id={index}
                />
              ))}
          </Playlist>
        )}
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default CurrentPlaylistPage;
