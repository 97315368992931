import React from 'react';

const Progress_bar = ({ progress, width, margin, mediaWidth }) => {
  const currentWidth = window.innerWidth > 768 ? width : mediaWidth;
  const parentDiv = {
    height: 5,
    width: currentWidth,
    backgroundColor: '#56245D',
    borderRadius: 13,
    margin: margin,
  };

  const childDiv = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: '#D154C5',
    borderRadius: 40,
    textAlign: 'right',
  };

  return (
    <div style={parentDiv}>
      <div style={childDiv}></div>
    </div>
  );
};

export default Progress_bar;
