/**
 * External dependencies
 */
import React, { useContext } from 'react';
import jwt_decode from 'jwt-decode';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
/**
 * Internal dependencies
 */
import Button from 'components/button/button';
import Modal from 'components/modal/modal';
import FieldValidated from '../../../components/field-validated/field-validated';

import emailPattern from '../../../helpers/email-pattern';

import AuthContext from 'domain/auth/contexts/auth-context/auth-context';
import toTitleCase from '../../../utils/toTitleCase';

const WelcomeModal = ({ visible, onClose }) => {
  const { previewToken } = useContext(AuthContext);
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      emailInput: '',
    },
  });
  const token =
    previewToken &&
    previewToken !== 'ACCESS DENIED' &&
    previewToken !== 'TOKEN EXPIRED'
      ? jwt_decode(previewToken)
      : {};

  const existingEmail = token?.email || '';

  const handleStart = ({ email = existingEmail }) => {
    if (!email) {
      const fields = [
        {
          name: 'email',
          value: email,
        },
        {
          name: 'preview_url',
          value: window.location.href,
        },
      ];

      const data = {
        fields,
      };

      const config = {
        method: 'post',
        url: 'https://api.hsforms.com/submissions/v3/integration/submit/2621188/8aa7c928-b4e9-470d-96f5-fb18502f8fc4',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(data),
      };

      axios(config).then(() => onClose());
    } else {
      onClose();
    }
  };

  return (
    <Modal visible={visible} onClose={() => onClose()}>
      <div
        className="modal-window__container"
        style={{ height: 'auto', position: 'relative' }}
      >
        <div className="modal-window__imgcontainer">
          <img
            className="modal-window__graphics2"
            src={require('assets/images/modals/Ellipse 1.png').default}
            alt="Ellipse 1"
          />
          <img
            className="modal-window__graphics1"
            src={require('assets/images/modals/carry 1.png').default}
            alt="carry 1"
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <p className="modal-window__title">
            {token?.clientName
              ? `Welcome,  ${toTitleCase(token?.clientName)} 👋`
              : 'Welcome 👋'}
          </p>
          <p className="modal-window__subtitle">
            Your curated music collection is here.
          </p>
          <FormProvider {...methods}>
            {!existingEmail && (
              <FieldValidated
                type="email"
                name="email"
                placeholder="name@domain.com"
                validations={{
                  required: 'Email address is required',
                  pattern: {
                    value: emailPattern,
                    message: 'Email address is not valid',
                  },
                }}
                style={{
                  borderColor: '#fff',
                  width: '90%',
                  margin: '0 auto',
                  marginBottom: 10,
                  padding: 10,
                }}
              />
            )}

            <Button
              modifier="white2"
              onClick={methods.handleSubmit(handleStart)}
              style={{ marginBottom: 20 }}
            >
              Start!
            </Button>
          </FormProvider>
          <div
            onClick={onClose}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              height: 20,
              width: 20,
              borderTopRightRadius: 13,
              backgroundColor: '#3baba1',
            }}
          ></div>
        </div>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
