import React, { useState, useContext } from 'react';
import BoardingWrapper from '../boarding-wrapper';
import { BoardingContext } from '../context/boardingContext';
import Tag from './category/tag';

const AvoidGenre = ({ genres }) => {
  const { companyName, genreValues, avoidGenreValues, handleAvoidGenres } =
    useContext(BoardingContext);

  const curatedGenres = [...genres].filter((item) => item !== 'Holiday');
  return (
    <BoardingWrapper
      progress={{ firstBar: '100', secondBar: '100', thirdBar: '33' }}
    >
      <div className="modal-boarding">
        <p className="modal-boarding__subtitle">{companyName}</p>
        <p className="modal-boarding__title">Things to avoid</p>
        <p className="modal-boarding__info">Select up to five genres</p>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
          }}
        >
          {curatedGenres.map((item) => (
            <Tag
              key={item}
              title={item}
              isSelected={avoidGenreValues?.includes(item)}
              isDisabled={genreValues?.includes(item)}
              handleOnClick={() => handleAvoidGenres(item)}
            />
          ))}
        </div>
      </div>
    </BoardingWrapper>
  );
};

export default AvoidGenre;
