/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import SearchSection from 'components/search-section/search-section';
import AllPlaylists from 'domain/music/blocks/all-vibes';
import AllGenres from 'domain/music/blocks/all-genres';
import AllIntensities from 'domain/music/blocks/all-intensities';

import AllSceneCategories from 'domain/music/blocks/all-scene-categories';
import capitalize from 'helpers/capitalize';

const MusicSearchCollection = ({
  match: {
    params: { id, collectionId },
  },
}) => {
  const renderItems = () => {
    if (collectionId === 'vibe')
      return <AllPlaylists deviceId={id} collectionId={collectionId} />;
    if (collectionId === 'scenes') return <AllSceneCategories deviceId={id} />;
    if (collectionId === 'genres') return <AllGenres deviceId={id} />;
    if (collectionId === 'intensities') return <AllIntensities deviceId={id} />;
  };

  return (
    <DashboardLayout
      subtitle="Music"
      title={capitalize(collectionId)}
      backgroundImage={`url(${
        require('assets/svg-icons/background-OR.svg').default
      })`}
      modifier="music-search"
    >
      <SearchSection deviceId={id}>{renderItems()}</SearchSection>
    </DashboardLayout>
  );
};
// title={`All ${collectionId}`}

export default MusicSearchCollection;
