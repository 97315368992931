import React, { useState } from 'react';
import { ReactComponent as ArrowDown } from 'assets/svg-icons/arrow-down-circle.svg';
import cx from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import * as rdd from 'react-device-detect';
import HelperCards from './helper-cards';

const HelperBar = () => {
  const history = useHistory();
  const [showHelperBar, setShowHelperBar] = useState('closed');
  const { id } = useParams();
  const handleOpenHelperBar = () => {
    !rdd.isMobileOnly && showHelperBar === 'closed'
      ? setShowHelperBar('opened')
      : setShowHelperBar('closed');
    rdd.isMobileOnly && history.push(`/user/${id}/generated-content/actions`);
  };
  return (
    <div
      className={cx('helper-bar', showHelperBar)}
      style={{ ...(showHelperBar === 'closed' && { cursor: 'pointer' }) }}
      onClick={() =>
        showHelperBar === 'closed' ? handleOpenHelperBar() : null
      }
    >
      <div className="helper-bar__text">
        <h3>
          {showHelperBar === 'closed'
            ? 'Ready to sign up or need custom music curation?'
            : 'Get back to the tunes'}
        </h3>
        <ArrowDown
          className={cx('helper-bar__icon', showHelperBar)}
          onClick={handleOpenHelperBar}
        />
      </div>
      {!rdd.isMobileOnly && (
        <div className={cx('helper-bar__content', showHelperBar)}>
          {showHelperBar === 'opened' && <HelperCards id={id} />}
        </div>
      )}
    </div>
  );
};

export default HelperBar;
