/**
 * External dependencies
 */
import { useMemo } from 'react';
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import {
  SIGNIN_USER,
  SIGNIN_USER_FACEBOOK,
  SIGNIN_USER_APPLE,
} from 'domain/auth/mutations/signin-user';

export default function useLogin(provider = 'email') {
  const providerMutations = useMemo(
    () => [
      { id: 'email', mutation: SIGNIN_USER },
      { id: 'facebook', mutation: SIGNIN_USER_FACEBOOK },
      { id: 'apple', mutation: SIGNIN_USER_APPLE },
    ],
    []
  );
  const currentProviderMutation = useMemo(
    () => providerMutations.find((pr) => pr.id === provider),
    [provider, providerMutations]
  );
  return useMutation(currentProviderMutation.mutation);
}
