/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Internal dependencies
 */
import cx from 'classnames';
import Button from '../button/button';

import getRandomColor from '../../helpers/get-random-color';
import _ from 'lodash';
import * as rdd from 'react-device-detect';

const CardLink = ({
  to,
  title,
  subtitle,
  description,
  color = '#670BCE',
  graphic,
  graphicAlign,
  vertical,
  cover,
  style,
  hasButton,
  handleClick,
  buttonText,
  customStyle,
}) => {
  const history = useHistory();
  const [showPlayButton, setShowPlayButton] = useState(false);
  const [sourceLoaded, setSourceLoaded] = useState(color);

  useEffect(() => {
    if (cover) {
      const img = new Image();
      img.src = cover;
      img.onload = () => setSourceLoaded(`url(${cover})`);
    }
  }, [cover]);

  const changeRoute = () => {
    history.push(to);
  };

  return (
    <div className={cx({ square: !rdd.isMobileOnly && !vertical })}>
      <div
        onMouseOver={() => setShowPlayButton(true)}
        onMouseLeave={() => setShowPlayButton(false)}
        onClick={changeRoute}
        className={cx('card-link content', { 'card-link--vertical': vertical })}
        style={{
          background: sourceLoaded,
          transition: 'all 1s',
          ...style,
          cursor: 'pointer',
          ...(sourceLoaded !== color && { backgroundSize: 'cover' }),
        }}
      >
        {graphic && (
          <span
            className={cx('card-link__graphic', graphicAlign)}
            style={customStyle}
          >
            <img src={graphic} alt="" />
          </span>
        )}

        <span className="card-link__content">
          {title && <h4 style={{ zIndex: hasButton ? 10 : 0 }}>{title}</h4>}

          <div className="card-link__entry">
            {subtitle && <h5>{subtitle}</h5>}

            {description && <p>{description}</p>}
          </div>
        </span>

        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            background: getRandomColor(_.random(14)),
            top: 0,
            left: 0,
            opacity: showPlayButton ? 0.9 : 0,
            transition: 'all .3s',
          }}
        />

        {/* {cover && (
				<span
					className="card-link__cover"
					style={{backgroundImage: `url(${cover})`}}
				></span>
			)} */}
        {hasButton && (
          <Button
            style={{
              padding: '19px 25px',
              position: 'absolute',
              bottom: 20,
              right: 20,
              opacity: showPlayButton ? 1 : 0,
              transition: 'all .3s',
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleClick();
            }}
            modifier="primary"
          >
            {buttonText || 'Play now'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default CardLink;
