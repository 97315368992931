/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const GENERATE_MUSIC_LIST = gql`
  mutation generateMusicListMutation {
    generateMusicList {
      id
      preview {
        previewId
      }
    }
  }
`;
