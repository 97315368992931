/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import CardLinks from 'components/card-links/card-links';
import CardLink from 'components/card-link/card-link';
import Field from 'components/field/field';
import SearchSvg from '../../../assets/svgComponents/SearchSvg';
import LoadingProvider from '../../../components/loading-provider/loading-provider';
import Page404 from 'views/404/404';

import WelcomeModal from 'domain/music/blocks/welcome-modal';

import * as rdd from 'react-device-detect';
import PreviewWelcomeCard from '../../../components/preview-welcome-card/preview-welcome-card';
import PreviewWelcomeCardMobile from '../../../components/preview-welcome-card/preview-welcome-card-mobile';
import useAuth from '../../auth/hooks/use-auth';

const MusicPageUser = ({ location: { pathname } }) => {
  const { previewToken, welcomeModal, setWelcomeModal } = useAuth();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState();

  if (previewToken === 'ACCESS DENIED') {
    return <Page404 />;
  }

  if (previewToken === 'TOKEN EXPIRED') {
    return (
      <Page404
        message="This preview link has expired, please contact us for a new link at support@activaire.com."
        errorNumber={403}
      />
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!searchValue) {
      return;
    }

    history.push(`${pathname}/search/${searchValue}`);
  };

  return (
    <LoadingProvider loading={!previewToken}>
      <WelcomeModal
        visible={welcomeModal}
        onClose={() => setWelcomeModal(false)}
      />
      <DashboardLayout
        isOnMusicRoute
        title="Music"
        backgroundImage={`url(${
          require('assets/svg-icons/background-OR.svg').default
        })`}
        modifier="music"
      >
        <div className="music-search">
          {rdd.isMobileOnly && (
            <form
              action="?"
              onSubmit={handleSubmit}
              className="page__head"
              style={{
                display: 'inherit',
                paddingTop: 0,
              }}
            >
              <Field className="music__button" type="submit" value="Search" />
              <Field
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                className="music-field"
                type="search"
                icon={<SearchSvg />}
                style={{ borderColor: '#393B5A' }}
              />
            </form>
          )}

          {rdd.isMobileOnly ? (
            <PreviewWelcomeCardMobile pathname={pathname} />
          ) : (
            <PreviewWelcomeCard pathname={pathname} />
          )}

          <h4
            style={{
              fontSize: 16,
              marginBottom: rdd.isMobileOnly ? 7 : 20,
              marginLeft: rdd.isMobileOnly ? 13 : 0,
              marginTop: rdd.isMobileOnly ? 48 : 20,
            }}
          >
            Discover Playlists and Scenes
          </h4>

          <CardLinks large>
            <CardLink
              to={`${pathname}/search/all/scenes`}
              title="Scenes"
              description="Expertly curated Scenes for every business, from restaurants, shops, and hotels, to offices, schools, and fitness centers."
              color="#C361E9"
              graphic={require('assets/images/card-image-1.png').default}
              graphicAlign="right bottom"
              customStyle={{ mixBlendMode: 'overlay' }}
            />

            <CardLink
              to={`${pathname}/search/all/genres`}
              title="Genres"
              description="Discover new and classic tracks sorted by the genre. Rock out, get jazzy, or add a splash of Pop whenever the mood strikes."
              color="#670BCE"
              graphic={require('assets/images/card-image-2.png').default}
              graphicAlign="center bottom"
            />

            <CardLink
              to={`${pathname}/search/all/intensities`}
              title="Intensities"
              description="Set the perfect pace ranging from easy and smooth to upbeat and eclectic."
              color="#0BCEC9"
              graphic={require('assets/images/card-image-3.png').default}
              graphicAlign="right top"
            />

            <CardLink
              to={`${pathname}/search/all/vibe`}
              title="Vibes"
              description="Find the vibe that resonates with your crowd. Pick things and get energetic or mellow out with a wash of calm tunes."
              color="#6183E9"
              graphic={require('assets/images/card-image-4.png').default}
              graphicAlign="center bottom"
            />
          </CardLinks>
        </div>
      </DashboardLayout>
    </LoadingProvider>
  );
};

export default MusicPageUser;
