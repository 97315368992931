/**
 * External dependencies
 */
import React from 'react';
import { createPortal } from 'react-dom';

/**
 * Internal dependencies.
 */
import portalsContainer from 'helpers/portals-container';
import Frame1 from '../../assets/images/preview/Frame1.png';
import Frame2 from '../../assets/images/preview/Frame2.png';
import Frame3 from '../../assets/images/preview/Frame3.png';
import Frame4 from '../../assets/images/preview/Frame4.png';
import Frame5 from '../../assets/images/preview/Frame5.png';
import Frame6 from '../../assets/images/preview/Frame6.png';
import Frame7 from '../../assets/images/preview/Frame7.png';
import Frame8 from '../../assets/images/preview/Frame8.png';
import Frame9 from '../../assets/images/preview/Frame9.png';

const Modal = ({
  subtitle,
  title,
  children,
  visible,
  styles,
  selectedStack,
}) => {
  if (!visible) return null;

  const stacks = {
    'boarding-start': Frame1,
    social: Frame2,
    category: Frame3,
    'category-specific': Frame4,
    genre: Frame5,
    artists: Frame6,
    'avoid-genre': Frame7,
    vibe: Frame8,
    explicit: Frame9,
    'boarding-end': null,
  };

  return createPortal(
    <div className="modal__wizard">
      {/* <Button type="button" onClick={onClose} className="modal__close">
        <IcoX />
      </Button> */}
      <div className="modal__inner-boarding" style={{ ...styles }}>
        <div className="modal__head">
          {subtitle && <h5>{subtitle}</h5>}
          {title && <h2>{title}</h2>}
        </div>
        <div className="modal__body-boarding">
          {children}
          {stacks[selectedStack] && (
            <div className="modal-window__artwork">
              <img src={stacks[selectedStack]} alt="Intro screens" />
            </div>
          )}
        </div>
      </div>
    </div>,
    portalsContainer
  );
};

export default Modal;
