/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { TOGGLE_SERVICE_TYPE } from '../mutations/toggle-service-type';

export default function useToggleServiceType() {
  const [toggleServiceType, { loading, data: userData }] = useMutation(
    TOGGLE_SERVICE_TYPE
  );

  return { toggleServiceType, loading, userData };
}
