/**
 * External dependencies
 */
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { PREVIEW_TOKEN } from 'domain/music/queries/preview-token';

export default function usePreviewToken(previewId, type) {
  const { data, loading, error } = useQuery(PREVIEW_TOKEN, {
    variables: {
      previewId: previewId || '',
      type: type || '',
    },
    fetchPolicy: 'network-only',
  });

  return {
    previewToken: data?.viewer?.fetchPreviewToken,
    loading,
    error,
  };
}
