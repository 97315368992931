import React from 'react';

import SearchSection from 'components/search-section/search-section';
import DashboardLayout from 'layouts/dashboard-layout';
import LoadingProvider from 'components/loading-provider/loading-provider';
import capitalize from '../../../helpers/capitalize';
import useAllScenes from '../hooks/use-all-scenes';
import CardLink from 'components/card-link/card-link';
import useAuth from '../../auth/hooks/use-auth';
import Card from '../components/card';
import * as rdd from 'react-device-detect';

const SceneSubCategories = ({
  match: {
    params: { id, category },
  },
}) => {
  const { previewUser, viewableCategories } = useAuth();
  const path = previewUser ? `user/${id}` : id;
  const {
    allScenes,
    allFeaturedScenes,
    loading: loadingScenes,
  } = useAllScenes();

  const clientCategory =
    viewableCategories?.find((item) => item.name?.toLowerCase() === category) ||
    {};

  const scenesByCategories = [...allScenes, ...allFeaturedScenes]?.filter(
    (item) =>
      item.newCategory?.filter(
        (item2) => item2.category === clientCategory?.name
      )?.length > 0
  );
  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle="Music"
      title={capitalize(category)}
      modifier="music-search"
    >
      <LoadingProvider loading={loadingScenes}>
        {rdd?.isMobileOnly && (
          <h4 style={{ fontSize: 13, padding: '0px 0px 15px 13px' }}>
            Business Types
          </h4>
        )}
        <div
          style={
            rdd?.isMobileOnly
              ? {
                  width: '100vw',
                  marginBottom: 135,
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gridGap: 13,
                  padding: '0px 13px',
                }
              : {}
          }
        >
          {[...(clientCategory?.subCategories || [])]
            .sort()
            .map((subCategory, index) => {
              const filteredScenes = scenesByCategories
                ?.filter(
                  (item) =>
                    item.newCategory?.filter(
                      (item2) =>
                        item2.subCategories
                          .map((item3) => item3.toLowerCase())
                          .includes(subCategory.toLowerCase()) &&
                        item2.category.toLowerCase() === category.toLowerCase()
                    )?.length > 0
                )

                ?.sort((a, b) => {
                  const nameA = a?.name.toUpperCase(); // ignore upper and lowercase
                  const nameB = b?.name.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  // names must be equal
                  return 0;
                });

              return (
                filteredScenes.length > 0 &&
                (!rdd?.isMobileOnly ? (
                  <SearchSection
                    title={capitalize(subCategory)}
                    collectionId="scenes"
                    hasViewAll={false}
                    key={subCategory}
                  >
                    <div
                      style={{
                        maxWidth: 980,
                        width: 'auto',
                        display: 'grid',
                        gridTemplateColumns: rdd?.isMobileOnly
                          ? 'repeat(1, 1fr)'
                          : 'repeat(4, 1fr)',
                        gridGap: 20,
                        marginBottom:
                          clientCategory.subCategories.length - 1 === index &&
                          rdd?.isMobileOnly
                            ? 135
                            : 35,
                      }}
                    >
                      {filteredScenes.map((item) => {
                        return (
                          <CardLink
                            triggerPlayNow={{
                              playlistId: item?.id,
                              devicePlayType: 'SCENE',
                            }}
                            key={item?.id}
                            to={`/${path}/search/all/scene/${item?.id}`}
                            title={capitalize(item?.name?.toLowerCase())}
                            cover={item?.cover}
                            style={{
                              margin: rdd?.isMobileOnly ? '0px 26px' : 0,
                            }}
                            hasButton
                          />
                        );
                      })}
                    </div>
                  </SearchSection>
                ) : (
                  <Card
                    index={index}
                    key={subCategory}
                    title={subCategory}
                    to={`/${path}/search/all/scenes/${category.toLowerCase()}/${subCategory.toLowerCase()}`}
                  />
                ))
              );
            })}
        </div>
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default SceneSubCategories;
