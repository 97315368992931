/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const USER = gql`
  query UserQuery {
    viewer {
      user {
        id
        profile {
          name
          clientId
          companyWebsite
        }
        rolesConsole {
          roleAccess
          roleAudio
        }
        favouriteDevices
        featuredPlayables
        defaultDeviceId
        status {
          lastLogin {
            date
            ipAddr
            latitude
            longitude
          }
        }
        emails {
          address
        }
        serviceType
        defaultServiceType
        companyName
        viewableCategories {
          name
          subCategories
          sortOrder
        }
        musicProfile {
          category
          subCategory
          genres
          avoidGenres
          artists
          vibe
          explicit
        }
        socialProfile {
          twitter
          linkedIn
          instagram
          spotify
        }
        preview {
          musicList {
            sceneIds
            playlistIds
            featuredIds
            artistSceneIds
          }
        }
      }
    }
  }
`;
