import React, { useEffect, useContext } from 'react';

import Modal from '../../components/modal/modal-boarding';
import BoardingStart from './components/boarding-start';
import BoardingEnd from './components/boarding-end';
import Category from './components/category';
import Artists from './components/artists';
import Genre from './components/genre';
import Social from './components/social';
import AvoidGenre from './components/avoid-genre';
import Explicit from './components/explicit';
import Vibe from './components/vibe';
import CategorySpecific from './components/category-specific';

import { BoardingContext } from './context/boardingContext';
import useAllCategories from './hooks/use-all-categories';
import useAllGenresForSonos from '../music/hooks/use-all-genres-for-sonos';
import useAllVibes from './hooks/use-all-vibes';

const BoardingModal = ({ boardingModal, handleCloseModal }) => {
  const { allCategories } = useAllCategories();
  const { allVibes } = useAllVibes();
  const { allGenresForSonos } = useAllGenresForSonos();

  const { config, selectedStack, setSelectedStack } =
    useContext(BoardingContext);

  useEffect(() => {
    if (!config.emailAddress) {
      setSelectedStack('boarding-start');
    } else if (!config.selectedCategory) {
      setSelectedStack('category');
    } else if (!config.selectedSpecificCategory) {
      setSelectedStack('category-specific');
    } else if (
      !config.socialValues.Twitter &&
      !config.socialValues.LinkedIn &&
      !config.socialValues.Instagram &&
      !config.socialValues['Spotify / Apple Music / Tidal']
    ) {
      setSelectedStack('social');
    } else if (!config.genreValues.length) {
      setSelectedStack('genre');
    } else if (
      !config.artistValues['0'] &&
      !config.artistValues['1'] &&
      !config.artistValues['2']
    ) {
      setSelectedStack('artists');
    } else if (!config.avoidGenreValues.length) {
      setSelectedStack('avoid-genre');
    } else if (!config.vibeValues.length) {
      setSelectedStack('vibe');
    } else if (!config.isExplicit) {
      setSelectedStack('explicit');
    } else {
      setSelectedStack('boarding-start');
    }
  }, [boardingModal]);

  const filteredCategories = [...allCategories].filter(
    (item) => !['custom', 'scene', 'vibe'].includes(item?.name?.toLowerCase())
  );
  const renderComponents = (stackName) => {
    switch (stackName) {
      case 'boarding-start':
        return <BoardingStart />;
      case 'social':
        return <Social />;
      case 'category':
        return <Category categories={filteredCategories} />;
      case 'category-specific':
        return <CategorySpecific categories={filteredCategories} />;
      case 'genre':
        return <Genre genres={allGenresForSonos} />;
      case 'artists':
        return <Artists />;
      case 'avoid-genre':
        return <AvoidGenre genres={allGenresForSonos} />;
      case 'vibe':
        return <Vibe vibes={allVibes} />;
      case 'explicit':
        return <Explicit />;
      case 'boarding-end':
        return <BoardingEnd />;
    }
  };

  return (
    <Modal
      visible={boardingModal}
      onClose={handleCloseModal}
      selectedStack={selectedStack}
      onPlayCloseButton
    >
      {renderComponents(selectedStack)}
    </Modal>
  );
};

export default BoardingModal;
