/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';

/**
 * Internal dependencies
 */
import { UPDATE_DEVICE_PLAYLIST } from '../mutations/update-device-playlist';

export default function useUpdateDevicePlaylist() {
  const [updateDevicePlaylist, { loading, data }] = useMutation(
    UPDATE_DEVICE_PLAYLIST,
    {
      onError: (e) => console.log('Could not update device playlist: ', e),
    }
  );

  return { updateDevicePlaylist, loading, data };
}
