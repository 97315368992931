/**
 * External dependencies
 */
import React, { memo, useState, useEffect, useLayoutEffect } from 'react';
import * as rdd from 'react-device-detect';
import {
  useHistory,
} from 'react-router-dom';

/**
 * Internal dependencies
 */
import PoweredBy from 'components/powered-by/powered-by';

import SwitchSelector from 'react-switch-selector';

import TrialModal from 'domain/sonos/trial-modal';

import _ from 'lodash';


const PreviewWizardLayout = ({
  children,
  backgroundColor,
  modifier,
  onScroll,
  showSwitcher,
  isSinglePlaylist = false,
}) => {
  const history = useHistory();
  const [trialModal, setTrialModal] = useState(false);

  const [randomNumber, setRandomNumber] = useState();

  const [iconBackground, setIconBackground] = useState('transparent');

  useEffect(() => {
    setRandomNumber(_.random(-420, -100));
  }, []);

  const dashboardRef = React.useRef();

  useLayoutEffect(() => {
    dashboardRef?.current?.addEventListener('scroll', (e) => {
      if (e.target.scrollTop > 50) {
        setIconBackground('#3BABA1');
      } else {
        setIconBackground('transparent');
      }
    });

    dashboardRef?.current?.removeEventListener('scroll', (e) => {
      if (e.target.scrollTop > 50) {
        setIconBackground('#3BABA1');
      } else {
        setIconBackground('transparent');
      }
    });
  }, []);

  const handleOnCloseModal = () => {
    setTrialModal(false);
    if (rdd.isMobile) {
      window.location.href = 'sonos://something';
    }
  };

  const options = [
    {
      label: 'Curator',
      value: 'CURATOR',
      selectedBackgroundColor: '#fff',
    },
    {
      label: 'Sonos',
      value: 'SONOS',
      selectedBackgroundColor: '#fff',
    },
  ];

  return (
    <div className="preview" onScroll={onScroll} ref={dashboardRef}>
      <div className="preview__sidebar">
        <TrialModal visible={trialModal} onClose={() => handleOnCloseModal()} />
        <span className="preview__sidebar_text">
          <PoweredBy />
        </span>
      </div>


      {/*              MOBILE NAV             */}

      <div className="preview__content">
        <div className={`page page--${modifier}`}>
          <div
            className="page__background"
            style={{ backgroundColor: backgroundColor }}
          >
            <div
              className="page__background-image"
              style={{
                backgroundImage: `url(${
                  require('../assets/svg-icons/curator-background.svg').default
                })`,
                left: randomNumber + '%',
              }}
            ></div>
          </div>

          <div
            className="page__head"
            style={
              isSinglePlaylist
                ? { position: 'absolute', top: -10, zIndex: 5 }
                : {}
            }
          >
            {showSwitcher && (
              <div className="switcher" style={{ width: 139, height: 38 }}>
                <SwitchSelector
                  options={options}
                  initialSelectedIndex={
                    history.location.pathname === '/' ? 0 : 1
                  }
                  backgroundColor={'transparent'}
                  fontColor={'grey'}
                  selectedFontColor={'#000'}
                  border={'1px solid #fff'}
                  selectionIndicatorMargin={0}
                  wrapperBorderRadius={13}
                  optionBorderRadius={12}
                />
              </div>
            )}
          </div>

          <div className="page__body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default memo(PreviewWizardLayout);
