/**
 * External dependencies
 */
import React from 'react';
import * as rdd from 'react-device-detect';
/**
 * Internal dependencies
 */
import Modal from 'components/modal/modal';
import { ReactComponent as PlusCircle } from 'assets/svg-icons/plus-circle.svg';

const SignUpModal = ({ visible, onClose, cardTitle, cardColor }) => {
  const style = rdd.isMobileOnly
    ? {
        height: 310,
        width: '100%',
        position: 'relative',
        backgroundColor: cardColor,
      }
    : {
        height: 310,
        width: 480,
        position: 'relative',
        backgroundColor: cardColor,
      };
  return (
    <Modal
      visible={visible}
      onClose={() => onClose()}
      style={{ background: '#000' }}
    >
      <div className="modal-window__container" style={style}>
        <div style={{ textAlign: 'center' }}>
          <p className="modal-window__subtitle2">{cardTitle}</p>
          <PlusCircle className="helper-bar__close-icon" onClick={onClose} />
        </div>
      </div>
    </Modal>
  );
};

export default SignUpModal;
