import React, { useContext } from 'react';
import BoardingWrapper from '../boarding-wrapper';
import { BoardingContext } from '../context/boardingContext';
import InputBoarding from './input-boarding';

const platforms = [
  'Twitter',
  'LinkedIn',
  'Instagram',
  'Spotify / Apple Music / Tidal',
];

const Social = ({ setSelectedStack, stack }) => {
  const { companyName, handleSocialInputChange, socialValues } =
    useContext(BoardingContext);

  return (
    <BoardingWrapper
      setSelectedStack={setSelectedStack}
      stack={stack}
      progress={{ firstBar: '66', secondBar: '0', thirdBar: '0' }}
    >
      <div className="modal-boarding">
        <p className="modal-boarding__subtitle">{companyName}</p>
        <p className="modal-boarding__title">Your socials</p>
        <p className="modal-boarding__info" style={{ marginBottom: 20 }}>
          Help us get familiar with your brand
        </p>
        {platforms.map((platform, index) => (
          <InputBoarding
            value={socialValues[Object.keys(socialValues)[index]]}
            onChange={handleSocialInputChange}
            name={platform}
            key={platform}
            placeholder={platform}
            style={{ marginBottom: 10 }}
          />
        ))}
      </div>
    </BoardingWrapper>
  );
};

export default Social;
