import React, { useRef, useContext, useEffect } from 'react';

import Button from '../../components/button/button';
import ProgressBar from './components/progress-bar';

import useUpdateMusicProfile from './hooks/use-update-music-profile';
import { BoardingContext } from './context/boardingContext';
import useAuth from '../auth/hooks/use-auth';
import useCreateUser from '../auth/hooks/use-create-user';
import crypto from 'crypto';
import { generateRandomString } from '../../utils/generate-random-string';
import useLogin from '../auth/hooks/use-login';
import { toast } from 'react-toastify';

const BoardingWrapper = ({ disabled, progress, children }) => {
  const {
    config,
    setSelectedStack,
    selectedStack,
    stack,
    setOtherSpecificCategoryValue,
  } = useContext(BoardingContext);
  const wrapperRef = useRef();
  const [loginEmail] = useLogin('email');
  const { user, loginSuccess, newUserRegisteredHubspotEmail } = useAuth();
  const { createUser } = useCreateUser();

  const { updateMusicProfile } = useUpdateMusicProfile();

  const notifyInvalidEmail = () =>
    toast.dark(
      'Invalid email address, please check your email address and try again',
      {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: true,
        bodyStyle: { color: '#fff' },
        style: { backgroundColor: '#670BCE' },
      }
    );

  const handleUpdateMusicProfile = () => {
    updateMusicProfile({
      variables: {
        companyName: config.companyName,
        musicProfileProvider: {
          category: config.otherCategoryValue || config.selectedCategory,
          subCategory:
            config.otherSpecificCategoryValue?.length > 0
              ? config.otherSpecificCategoryValue
              : config.selectedSpecificCategory,
          genres: config.genreValues,
          avoidGenres: config.avoidGenreValues,
          artists: Object.values(config?.artistValues || {}),
          vibe: config.vibeValues,
          explicit: config.isExplicit,
        },
        socialProfileProvider: {
          twitter: config.socialValues?.Twitter,
          linkedIn: config.socialValues?.LinkedIn,
          instagram: config.socialValues?.Instagram,
          spotify: config?.socialValues['Spotify / Apple Music / Tidal'],
        },
        incomingService: 'preview',
      },
    });
  };

  const handleCreateUser = () => {
    const generatedPassword = generateRandomString(10);
    createUser({
      variables: {
        name: config.name,
        companyName: config.companyName,
        serviceType: 'curator',
        authProvider: {
          email: config.emailAddress,
          password: crypto
            .createHash('sha256')
            .update(generatedPassword)
            .digest('hex'),
        },
        incomingService: 'preview',
        companyWebsite: config.companyWebsite,
      },
    }).then((res) => {
      const isNewUserCreated = res.data.createUser?.newUser;
      if (isNewUserCreated) {
        handleLogin(generatedPassword);
      } else {
        console.error('User not created');
        handleLogin();
      }
      handleUpdateMusicProfile();
    });
  };

  const handleLogin = (generatedPassword = generateRandomString(10)) => {
    loginEmail({
      variables: {
        serviceType: 'curator',
        incomingService: 'preview',
        emailData: {
          email: config.emailAddress,
          password: crypto
            .createHash('sha256')
            .update(generatedPassword)
            .digest('hex'),
        },
      },
    })
      .then(({ data }) => {
        if (
          data?.signinUser &&
          data?.signinUser?.token &&
          data?.signinUser?.user
        ) {
          loginSuccess({
            token: data.signinUser.token,
            user: data.signinUser.user,
            provider: 'email',
          });
        }
      })
      .catch(() => {
        console.error('Login error after user creation');
      });
  };

  const handleContinue = () => {
    if (user) {
      handleUpdateMusicProfile();
    } else {
      // create user and update music profile
      newUserRegisteredHubspotEmail({
        firstname: config.name,
        email: config.emailAddress,
        company: config.companyName,
        servicetype: 'curator',
        loginmethod: 'email',
      }).then((hubspotRes) => {
        if (hubspotRes.status === 200) {
          handleCreateUser();
        } else if (hubspotRes === 'INVALID_EMAIL') {
          notifyInvalidEmail();
        } else if (hubspotRes === 'OTHER_ERROR') {
          handleCreateUser();
          // notifyOtherError();
        }
      });
    }
    setSelectedStack((currentStack) => stack[stack.indexOf(currentStack) + 1]);

    // handle "Other" category
    if (selectedStack === 'category') {
      if (config.selectedCategory === 'Other') {
        setOtherSpecificCategoryValue('Other');
        setSelectedStack(
          (currentStack) => stack[stack.indexOf(currentStack) + 1]
        );
      }
    }
  };
  const handleBack = () => {
    setSelectedStack((currentStack) => stack[stack.indexOf(currentStack) - 1]);
    // handle "Other" category
    if (selectedStack === 'genre') {
      if (config.selectedCategory === 'Other') {
        setSelectedStack(
          (currentStack) => stack[stack.indexOf(currentStack) - 1]
        );
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleContinue();
    }
  };

  useEffect(() => {
    wrapperRef.current.addEventListener('keypress', handleKeyPress);
  }, []);

  const mediaProgressBarWidth = (window.innerWidth - 58) / 3;

  return (
    <div className="modal-window__boarding">
      {/*  */}
      <div className="modal-window__boarding-bars">
        <ProgressBar
          width="210px"
          mediaWidth={mediaProgressBarWidth.toString() + 'px'}
          progress={progress.firstBar}
        />
        <ProgressBar
          margin="0px 9px"
          width="210px"
          mediaWidth={mediaProgressBarWidth.toString() + 'px'}
          progress={progress.secondBar}
        />
        <ProgressBar
          width="210px"
          mediaWidth={mediaProgressBarWidth.toString() + 'px'}
          progress={progress.thirdBar}
        />
      </div>
      {/*  */}
      <span
        style={{
          left: 10,
          cursor: 'pointer',
          width: 60,
          height: 20,
          borderRadius: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
      <div
        style={{ height: 'calc(100% - 95px)', overflow: 'auto' }}
        ref={wrapperRef}
      >
        {children}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          position: 'absolute',
          bottom: 13,
        }}
      >
        {selectedStack !== 'boarding-start' && (
          <Button
            modifier="as-link"
            onClick={handleBack}
            style={{
              color: '#3BABA1',
              marginRight: 20,
            }}
          >
            Back
          </Button>
        )}
        <Button
          disabled={disabled}
          modifier="primary"
          onClick={handleContinue}
          style={{
            padding: '12px 0px',
            width: 110,
            borderRadius: 13,
            height: 45,

            border: disabled ? 'none' : '',
            color: disabled ? '#FFFFFF' : '',
            backgroundColor: disabled ? '#3BABA180' : '',
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default BoardingWrapper;
