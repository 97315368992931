/**
 * External dependencies
 */
import React from 'react';
import stc from 'string-to-color';
import * as rdd from 'react-device-detect';
/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import CardLink from 'components/card-link/card-link';
import LoadingProvider from 'components/loading-provider/loading-provider';
import useAllScenes from '../hooks/use-all-scenes';
import useAuth from '../../auth/hooks/use-auth';
import MusicListItem from '../components/music-list-item';
import _ from 'lodash';

const SingleVibePage = ({
  match: {
    params: { id, vibe },
  },
}) => {
  const { previewUser } = useAuth();
  const path = previewUser ? `user/${id}` : id;
  const { allScenes, allFeaturedScenes } = useAllScenes({ deviceId: id });

  const allMixes = [...allScenes, ...allFeaturedScenes]?.filter((item) => {
    const genreUpperCase = item.vibe?.map((item) => item.toUpperCase());
    return genreUpperCase.includes(vibe);
  });

  return (
    <DashboardLayout
      isOnMusicRoute
      title={_.capitalize(vibe)}
      modifier="current-vibe"
    >
      <LoadingProvider loading={!allMixes}>
        {rdd?.isMobileOnly ? (
          <div style={{ marginBottom: 135 }}>
            <h4 style={{ fontSize: 13, padding: '0px 0px 15px 13px' }}>
              {allMixes.length} Scenes Available
            </h4>

            {allMixes?.map((item) => {
              return (
                <MusicListItem
                  key={item.id}
                  to={`/${path}/search/all/scene/${item.id}`}
                  cover={item.cover}
                  name={item.name}
                  description={item.description}
                />
              );
            })}
          </div>
        ) : (
          <div
            style={{
              maxWidth: 980,
              width: 'auto',
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gridGap: 20,
              marginBottom: 35,
            }}
          >
            {allMixes?.map((scene) => (
              <CardLink
                triggerPlayNow={{
                  playlistId: scene?.id,
                  devicePlayType: 'SCENE',
                }}
                key={scene?.id}
                to={`/${path}/search/all/scene/${scene.id}`}
                cover={scene?.cover}
                title={scene?.name}
                color={stc(scene?.name)}
                style={{ margin: 0 }}
              />
            ))}
          </div>
        )}
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default SingleVibePage;
