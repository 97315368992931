/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const SINGLE_PLAYLIST = gql`
  query Query(
    $id: String
    $previewToken: String
    $songLimit: Int
    $songSkip: Int
  ) {
    viewer {
      allPlaylists(filter: { _id_contains: $id }, previewToken: $previewToken) {
        edges {
          cursor
          node {
            id
            name
            cover
            tracks
            description
            featuredArtists
            files {
              id
            }
            allSongs(songLimit: $songLimit, songSkip: $songSkip) {
              id
              title
              album
              artist
              artworkUrl
              preview {
                url
              }
            }
          }
        }
      }
    }
  }
`;
