import HelperCard from './helper-card';
import React from 'react';

const HelperCardsMobile = ({ id }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <HelperCard
        showHelperBar
        title="Need to modify your answers?"
        description="If you’d like to change some of your responses to get different results, you can do that here."
        style={{
          background: '#219653',
          width: 'auto',
          height: 'auto',
          margin: '0 20px 20px 20px',
        }}
        buttonText="Modify"
      />
      <HelperCard
        showHelperBar
        title="Sign Up"
        description="We hope you enjoyed this preview curated especially for your business. If you’d like to learn more about how our platform works, click here. Pricing starts at just $35 per month per zone. To learn more about our pricing options, click here."
        description2="Take your brand’s music experience to the next level."
        style={{
          background: '#893BC6',
          width: 'auto',
          height: 'auto',
          margin: '0 20px 20px 20px',
        }}
        buttonText="Get Curator"
      />
      <HelperCard
        showHelperBar
        title="Looking to get a little more personal? Let’s chat about custom programming."
        description="Customized music programs are a powerful way to communicate your brands’ values, messaging, ethos, and DNA. Work with our Curation Team to develop an Audio Brand centered on your unique identity."
        description2="Let’s find your inner rythms."
        style={{
          background: '#20349F',
          width: 'auto',
          height: 'auto',
          margin: '0 20px 20px 20px',
        }}
        buttonText="Contact Sales"
      />
    </div>
  );
};

export default HelperCardsMobile;
