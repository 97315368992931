/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const ALL_SCENES = gql`
  query allScenesQuery(
    $allScenesFirst: Int
    $allScenesAfter: String
    $allScenesFilter: SceneFilter
    $allScenesDeviceId: String
    $previewToken: String
  ) {
    viewer {
      allScenes(
        first: $allScenesFirst
        after: $allScenesAfter
        filter: $allScenesFilter
        deviceId: $allScenesDeviceId
        previewToken: $previewToken
      ) {
        featuredIds
        edges {
          cursor
          node {
            id
            name
            category
            subCategory
            description
            featuredArtists
            image
            genres
            vibe
            newCategory {
              category
              subCategories
            }
          }
        }
        edgesArtists {
          cursor
          node {
            id
            name
            category
            subCategory
            description
            featuredArtists
            image
            genres
            vibe
            newCategory {
              category
              subCategories
            }
          }
        }
      }
    }
  }
`;
