/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const VIEWABLES = gql`
  query FetchViewables($previewId: String!, $type: String) {
    viewer {
      fetchViewables(previewId: $previewId, type: $type) {
        name
        subCategories
        sortOrder
      }
    }
  }
`;
