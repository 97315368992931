/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const SEARCH_PLAYLISTS = gql`
  query searchPlaylists($text: String!, $previewToken: String) {
    viewer {
      searchPlaylists(text: $text, previewToken: $previewToken) {
        __typename
        ... on Scene {
          id
          name
          image
          description
          category
          subCategory
        }
        ... on Playlist {
          id
          name
          cover
          description
        }
      }
    }
  }
`;
