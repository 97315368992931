/**
 * External dependencies
 */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { Howler } from 'howler';

/**
 * Internal dependencies
 */
import Routes from 'components/routes/routes';

function App() {
  useEffect(() => {
    Howler.stop();
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Routes />
    </div>
  );
}

export default withRouter(App);
