/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */

const Page404 = ({
  message = "Unfortunately the page you've requested doesn't exist. Please contact us to obtain a valid preview link at support@activaire.com.",
  errorNumber = 404,
}) => {
  return (
    <section className="section-404">
      <div className="section-404__title">
        <h3>Error {errorNumber}</h3>

        <h1>{message}</h1>
      </div>
    </section>
  );
};

export default Page404;
