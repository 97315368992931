/**
 * External dependencies
 */
import React, { memo, useState, useEffect, useLayoutEffect } from 'react';
import * as rdd from 'react-device-detect';
import {
  NavLink,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom';

/**
 * Internal dependencies
 */
import PoweredBy from 'components/powered-by/powered-by';

import SwitchSelector from 'react-switch-selector';

import { ReactComponent as IcoBack } from 'assets/svg-icons/ico-back.svg';
import { ReactComponent as IcoHome } from 'assets/svg-icons/ico-home.svg';
import { ReactComponent as IcoBell } from 'assets/svg-icons/ico-bell.svg';
import { ReactComponent as IcoMusic } from 'assets/svg-icons/ico-music.svg';
import { ReactComponent as IcoSupport } from 'assets/svg-icons/ico-support.svg';
import { ReactComponent as IcoProfile } from 'assets/svg-icons/ico-profile.svg';
import { ReactComponent as IcoDevices } from 'assets/svg-icons/ico-devices.svg';

import { ReactComponent as IcoX } from 'assets/svg-icons/ico-x.svg';
import useAuth from 'domain/auth/hooks/use-auth';

import useToggleServiceType from 'domain/sonos/hooks/use-toggle-service-type';
import useUpdateDevicePlaylist from 'domain/dashboard/hooks/use-update-device-playlist';
import TrialModal from 'domain/sonos/trial-modal';

import _ from 'lodash';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DashboardLayout = ({
  children,
  subtitle = 'Curator',
  title,
  backgroundColor,
  modifier,
  onScroll,
  showSwitcher,
  isSinglePlaylist = false,
}) => {
  const {
    path,
    params: { id },
  } = useRouteMatch();
  const history = useHistory();
  let query = useQuery();
  const [sonosTrial, setSonosTrial] = useState(query.get('sonosTrial'));
  const [trialModal, setTrialModal] = useState(false);

  const { user, setUser, previewUser } = useAuth();

  const hideBackButton = path === '/user/:id' || path === '/:id';

  const handleGoBack = (e) => {
    if (
      path === '/' ||
      path === '/:id' ||
      path === '/:id/dashboard' ||
      path === '/user/:id'
    )
      return;

    history.goBack();

    e.preventDefault();
  };

  const { toggleServiceType } = useToggleServiceType();
  const [randomNumber, setRandomNumber] = useState();

  const { updateDevicePlaylist, loading, data } = useUpdateDevicePlaylist();

  const [iconBackground, setIconBackground] = useState('transparent');

  useEffect(() => {
    setRandomNumber(_.random(-420, -100));
  }, []);

  useEffect(() => {
    if (sonosTrial === 'activate') {
      const newUser = JSON.parse(JSON.stringify(user));
      newUser.profile.sonosPermit = true;
      if (newUser.stripe) {
        newUser.stripe.sonosTrial = true;
      }
      setUser(newUser);
      setTrialModal(true);
      setSonosTrial(false);
    }
  }, [sonosTrial]);

  const dashboardRef = React.useRef();

  useLayoutEffect(() => {
    dashboardRef?.current?.addEventListener('scroll', (e) => {
      if (e.target.scrollTop > 50) {
        setIconBackground('#3BABA1');
      } else {
        setIconBackground('transparent');
      }
    });

    dashboardRef?.current?.removeEventListener('scroll', (e) => {
      if (e.target.scrollTop > 50) {
        setIconBackground('#3BABA1');
      } else {
        setIconBackground('transparent');
      }
    });
  }, []);

  const handleSwitch = (e) => {
    if (e === 'SONOS') {
      toggleServiceType({ variables: { serviceType: e } });
      history.push('/sonos');
      setUser(Object.assign({}, user, { defaultServiceType: e }));
    } else {
      toggleServiceType({ variables: { serviceType: e } });
      history.push('/');
      setUser(Object.assign({}, user, { defaultServiceType: e }));
    }
  };

  const handleOnCloseModal = () => {
    setTrialModal(false);
    if (rdd.isMobile) {
      window.location.href = 'sonos://something';
    }
  };

  const options = [
    {
      label: 'Curator',
      value: 'CURATOR',
      selectedBackgroundColor: '#fff',
    },
    {
      label: 'Sonos',
      value: 'SONOS',
      selectedBackgroundColor: '#fff',
    },
  ];

  return (
    <div className="dashboard" onScroll={onScroll} ref={dashboardRef}>
      {!hideBackButton && (
        <NavLink
          onClick={handleGoBack}
          to="/"
          className="mobile-icon"
          style={{
            backgroundColor: iconBackground,
          }}
        >
          {path === '/:id' ||
          path === '/:id/dashboard' ||
          path === '/user/:id' ? (
            <IcoX />
          ) : (
            <IcoBack />
          )}
        </NavLink>
      )}
      <div className="dashboard__sidebar">
        <TrialModal visible={trialModal} onClose={() => handleOnCloseModal()} />
        <ul>
          {path !== '/:id' && path !== '/user/:id' && (
            <li>
              <NavLink
                onClick={handleGoBack}
                to="/"
                activeClassName="is-current"
                exact={true}
              >
                {path === '/' ? <IcoDevices /> : <IcoBack />}

                <span className="dashboard__sidebar_text">
                  {path === '/' || path === '/:id' || path === '/user/:id'
                    ? 'Devices'
                    : 'Back'}
                </span>
              </NavLink>
            </li>
          )}
          {id && id !== 'sonos' && (
            <>
              <li>
                <NavLink
                  to={previewUser ? `/user/${id}` : `/${id}`}
                  activeClassName="is-current"
                  exact={true}
                >
                  <IcoMusic />
                  <span className="dashboard__sidebar_text">Music</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  exact={true}
                  to={`/${id}/highlights`}
                  activeClassName="is-current"
                >
                  <IcoBell />
                  <span className="dashboard__sidebar_text">Articles</span>
                </NavLink>
              </li>
            </>
          )}
          {id !== 'sonos' && !path.includes('sonos') && (
            <>
              <li>
                <NavLink
                  to={id ? `/${id}/support` : '/support'}
                  activeClassName="is-current"
                >
                  <IcoSupport />
                  <span className="dashboard__sidebar_text">Support</span>
                </NavLink>
              </li>
            </>
          )}
          {((id && id === 'sonos') || path.includes('sonos')) && (
            <>
              <li>
                <NavLink
                  to={
                    user?.stripe?.sonosTrial
                      ? `/sonos/dashboard`
                      : `/sonos/trial`
                  }
                  activeClassName="is-current"
                  exact={true}
                >
                  <IcoHome />
                  <span className="dashboard__sidebar_text">Home</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`/sonos/highlights`} activeClassName="is-current">
                  <IcoBell />
                  <span className="dashboard__sidebar_text">Highlights</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={
                    id && id !== 'sonos' ? `/${id}/support` : '/sonos/support'
                  }
                  activeClassName="is-current"
                >
                  <IcoSupport />
                  <span className="dashboard__sidebar_text">Support</span>
                </NavLink>
              </li>

              <li>
                <NavLink to={'/sonos/profile'} activeClassName="is-current">
                  <IcoProfile />
                  <span className="dashboard__sidebar_text">Profile</span>
                </NavLink>
              </li>
            </>
          )}
        </ul>

        <span className="dashboard__sidebar_text">
          <PoweredBy />
        </span>
      </div>

      {/*              MOBILE NAV             */}
      <ul className="mobile-default-nav">
        {((id && id === 'sonos') || path.includes('sonos')) && (
          <>
            <li>
              <NavLink
                to={
                  user?.stripe?.sonosTrial ? `/sonos/dashboard` : `/sonos/trial`
                }
                activeClassName="is-current"
                exact={true}
              >
                <IcoHome style={{ marginBottom: 10 }} />
                Home
              </NavLink>
            </li>
            <span
              style={{
                backgroundColor: '#fff',
                height: 50,
                width: 1,
                opacity: 0.25,
              }}
            ></span>
            <li>
              <NavLink to={`/sonos/highlights`} activeClassName="is-current">
                <IcoBell style={{ marginBottom: 10 }} />
                Highlights
              </NavLink>
            </li>

            <li>
              <NavLink
                to={id && id !== 'sonos' ? `/${id}/support` : '/sonos/support'}
                activeClassName="is-current"
              >
                <IcoSupport style={{ marginBottom: 10 }} />
                Support
              </NavLink>
            </li>

            <li>
              <NavLink to={'/sonos/profile'} activeClassName="is-current">
                <IcoProfile style={{ marginBottom: 10 }} />
                Profile
              </NavLink>
            </li>
          </>
        )}

        {id && id !== 'sonos' && (
          <>
            <li>
              <NavLink
                to={previewUser ? `/user/${id}` : `/${id}`}
                activeClassName="is-current"
                exact={true}
              >
                <IcoMusic style={{ marginBottom: 10 }} />
                Music
              </NavLink>
            </li>

            <li>
              <NavLink
                to={`/${id}/highlights`}
                activeClassName="is-current"
                exact={true}
              >
                <IcoBell style={{ marginBottom: 10 }} />
                Articles
              </NavLink>
            </li>
          </>
        )}
        {id !== 'sonos' && !path.includes('sonos') && (
          <>
            {path === '/' && (
              <li>
                <NavLink
                  onClick={handleGoBack}
                  to="/"
                  activeClassName="is-current"
                  exact={true}
                >
                  <IcoDevices style={{ marginBottom: 10 }} />

                  {'Devices'}
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                to={id ? `/${id}/support` : '/support'}
                activeClassName="is-current"
              >
                <IcoSupport style={{ marginBottom: 10 }} />
                Support
              </NavLink>
            </li>
          </>
        )}
      </ul>

      {/*              MOBILE NAV             */}

      <div className="dashboard__content">
        <div className={`page page--${modifier}`}>
          <div
            className="page__background"
            style={{ backgroundColor: backgroundColor }}
          >
            <div
              className="page__background-image"
              style={{
                backgroundImage: `url(${
                  require('../assets/svg-icons/curator-background.svg').default
                })`,
                left: randomNumber + '%',
              }}
            ></div>
          </div>

          <div
            className="page__head"
            style={
              isSinglePlaylist
                ? { position: 'absolute', top: -10, zIndex: 5 }
                : {}
            }
          >
            <div>
              <h5>{isSinglePlaylist ? <br /> : subtitle}</h5>

              <h2>{title}</h2>
            </div>
            {showSwitcher && (
              <div className="switcher" style={{ width: 139, height: 38 }}>
                <SwitchSelector
                  onChange={handleSwitch}
                  options={options}
                  initialSelectedIndex={
                    history.location.pathname === '/' ? 0 : 1
                  }
                  backgroundColor={'transparent'}
                  fontColor={'grey'}
                  selectedFontColor={'#000'}
                  border={'1px solid #fff'}
                  selectionIndicatorMargin={0}
                  wrapperBorderRadius={13}
                  optionBorderRadius={12}
                />
              </div>
            )}
          </div>

          <div className="page__body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default memo(DashboardLayout);
