/**
 * External dependencies
 */
import React, { useEffect, useContext } from 'react';
import { Switch, Route, useParams } from 'react-router-dom';

/**
 * Internal dependencies
 */
import MusicSearchCollection from 'domain/music/pages/music-search-all';
import MusicSearchPage from 'domain/music/pages/music-search';
import CurrentPlaylistPage from 'domain/music/pages/current-playlist';
import CuratedMixPage from 'domain/music/pages/curated-mix';
import SingleGenrePage from 'domain/music/pages/single-genre';
import SingleScenePage from 'domain/music/pages/single-scene';
import SingleIntensityPage from 'domain/music/pages/single-intensity';
import SceneSubCategoryPage from 'domain/music/pages/subcategory-page';
import SceneSubCategories from 'domain/music/pages/scene-subcategories';
import FeaturedPage from 'domain/music/pages/featured-page';
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';
import usePreviewToken from '../../music/hooks/use-preview-token';
import useViewables from '../hooks/use-viewables';
import SingleVibePage from '../pages/single-vibe';
import useUser from '../../auth/hooks/use-user';
import MusicPageUser from 'domain/music/pages/music-user';
import GeneratedContentPage from '../pages/generated-content';
import WhatDoYouDoPage from '../pages/what-do-you-do-page';
import YourMusicTastesPage from '../pages/your-music-tastes-page';
import OnBrandArtistsPage from '../pages/on-brand-artists-page';
import WhatsYourVibePage from '../pages/whats-your-vibe-page';
import GeneratedContentActionsPage from '../pages/generated-content-actions';

const MusicRoutesUser = () => {
  const {
    setPreviewToken,
    previewId: savedPreviewId,
    setPreviewId,
    viewableCategories,
    setViewableCategories,
    setPreviewUser,
    user,
    setUser,
  } = useContext(AuthContext);
  const { id } = useParams();

  const { previewToken } = usePreviewToken(id.toLowerCase(), 'user');
  const { viewables } = useViewables(id.toLowerCase(), 'user') || {};
  const { data: userQuery, loading } = useUser();

  useEffect(() => {
    if (!viewableCategories && viewables) {
      setViewableCategories(viewables);
    }
    if (
      previewToken &&
      viewables &&
      previewToken !== 'ACCESS DENIED' &&
      previewToken !== 'TOKEN EXPIRED' &&
      savedPreviewId !== id
    ) {
      setPreviewToken(previewToken);
      setPreviewId(id);
      setPreviewUser(true);
    }

    if (previewToken === 'ACCESS DENIED' || previewToken === 'TOKEN EXPIRED') {
      setPreviewToken(previewToken);
      setPreviewId(null);
      setViewableCategories([]);
      setPreviewUser(false);
      window.location.href = '/';
    }
  }, [previewToken, viewables]);

  useEffect(() => {
    if (!user && !loading && previewToken && userQuery?.id !== 'none') {
      setUser(userQuery);
      setPreviewUser(true);
    }
  }, [loading, previewToken]);

  return (
    <Switch>
      <Route exact path="/user/:id" component={MusicPageUser} />

      <Route exact path="/user/:id/curated-mix" component={CuratedMixPage} />

      <Route
        exact
        path="/user/:id/search/:searchTerm"
        component={MusicSearchPage}
      />

      <Route
        exact
        path="/user/:id/search/all/featured"
        component={FeaturedPage}
      />

      <Route
        exact
        path="/user/:id/search/all/:collectionId"
        component={MusicSearchCollection}
      />

      <Route
        exact
        path="/user/:id/search/:collectionId/:playlistId"
        component={CurrentPlaylistPage}
      />

      <Route
        exact
        path="/user/:id/search/all/genres/:genre"
        component={SingleGenrePage}
      />

      <Route
        exact
        path="/user/:id/search/all/vibe/:vibe"
        component={SingleVibePage}
      />

      <Route
        exact
        path="/user/:id/search/all/scenes/:category/:subcategory"
        component={SceneSubCategoryPage}
      />

      <Route
        exact
        path="/user/:id/search/all/scene/:sceneId"
        component={SingleScenePage}
      />

      <Route
        exact
        path="/user/:id/search/all/scenes/:category"
        component={SceneSubCategories}
      />

      <Route
        exact
        path="/user/:id/search/all/intensities/:intensity"
        component={SingleIntensityPage}
      />

      <Route
        exact
        path="/user/:id/generated-content"
        component={GeneratedContentPage}
      />

      <Route
        exact
        path="/user/:id/generated-content/actions"
        component={GeneratedContentActionsPage}
      />

      <Route
        exact
        path="/user/:id/generated-content/what-do-you-do"
        component={WhatDoYouDoPage}
      />

      <Route
        exact
        path="/user/:id/generated-content/your-music-tastes"
        component={YourMusicTastesPage}
      />

      <Route
        exact
        path="/user/:id/generated-content/on-brand-artists"
        component={OnBrandArtistsPage}
      />

      <Route
        exact
        path="/user/:id/generated-content/whats-your-vibe"
        component={WhatsYourVibePage}
      />
    </Switch>
  );
};

export default MusicRoutesUser;
