/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import * as rdd from 'react-device-detect';

/**
 * Internal dependencies
 */
import DashboardLayout from 'layouts/dashboard-layout';
import Page404 from 'views/404/404';
import Playlist from 'blocks/playlist/playlist';
import PlaylistMobile from 'blocks/playlist/playlist-mobile';
import useSingleScene from '../hooks/use-single-scene';

import { Howl } from 'howler';
import { SongItem } from './song-item';
import LoadingProvider from '../../../components/loading-provider/loading-provider';

const CurrentPlaylistPage = ({
  match: {
    params: { sceneId },
  },
}) => {
  const [songSkip, songLimit] = [0, 30];

  const { scene, loading } = useSingleScene(sceneId, songSkip, songLimit);

  const [allSongs, setAllSongs] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [playingIndex, setPlayingIndex] = useState(-1);

  useEffect(() => {
    if (!loading) {
      setAllSongs(scene?.songs || []);
    }
  }, [loading]);

  useEffect(() => {
    if (currentIndex > 0) {
      playPreview(currentIndex);
    }
  }, [currentIndex]);

  if (!loading && !scene) {
    return <Page404 />;
  }

  let sound = window.Howler._howls[0] || null;

  const stopSound = () => {
    sound?.stop();
    sound?.unload();
    window.Howler.unload();
    sound = null;
  };

  const playPreview = (index) => {
    const song = allSongs[index];

    if (sound !== null && song.preview.url !== sound._src) {
      stopSound();
    }

    if (sound === null || !sound.playing()) {
      startNewHowl(index);
    }

    if (sound !== null && sound.playing()) {
      setPlayingIndex(-1);
      stopSound();
    }
  };

  const startNewHowl = (index) => {
    stopSound();
    const song = allSongs[index];
    sound = new Howl({
      src: [song?.preview?.url || ''],
      onplay: () => {
        setPlayingIndex(index);
      },
      onend: () => {
        stopSound();
        if (index < allSongs.length - 1) {
          startNewHowl(index + 1);
        } else {
          setCurrentIndex(index + 1);
        }
      },
      html5: true,
    });
    sound?.play();
  };

  return (
    <DashboardLayout
      title={scene?.name}
      isSinglePlaylist={rdd.isMobileOnly && !!sceneId}
      playlistInfo={{
        deviceId: '',
        devicePlayType: 'SCENE',
        devicePlayTypeId: sceneId,
      }}
      modifier="current-playlist"
    >
      <LoadingProvider
        loading={loading}
        style={{ position: 'fixed', top: '50%', left: '50%' }}
      >
        {rdd.isMobileOnly ? (
          <PlaylistMobile
            devicePlayType="SCENE"
            playlistId={sceneId}
            description={scene?.featuredArtists.join(' - ')}
            title={scene?.description}
            cover={scene?.cover}
            handlePreview={() => playPreview(0)}
          >
            {allSongs.map((song, index) => (
              <SongItem
                key={song.id}
                isPlaying={playingIndex === index}
                song={song}
                onClick={() => playPreview(index)}
                id={index}
              />
            ))}
          </PlaylistMobile>
        ) : (
          <Playlist
            playlistId={sceneId}
            devicePlayType="SCENE"
            description={scene?.featuredArtists?.join(' - ')}
            title={scene?.description}
            cover={scene?.cover}
            showPreviewButton
            handlePreview={() => playPreview(0)}
          >
            {allSongs.map((song, index) => (
              <SongItem
                key={song.id}
                isPlaying={playingIndex === index}
                song={song}
                onClick={() => playPreview(index)}
                id={index}
              />
            ))}
          </Playlist>
        )}
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default CurrentPlaylistPage;
