/**
 * External dependencies
 */
import { useMemo, useContext } from 'react';
import { useQuery } from '@apollo/client';
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';
import _ from 'lodash';

/**
 * Internal dependencies
 */
import { ALL_AUDIO_DEVICES } from 'domain/devices/queries/all-audio-devices';

export default function useAllAudioDevices(options) {
  const { user } = useContext(AuthContext);

  let { data, loading, error, fetchMore } = useQuery(ALL_AUDIO_DEVICES, {
    variables: {
      allAudioDevicesFavouritesFirst: Math.max(
        user?.favouriteDevices?.length || 0,
        options?.first || 0
      ),
      allAudioDevicesFavouritesAfter: options?.after || null,
      allAudioDevicesFavouritesSort: {
        name: 'DSC',
      },
      allAudioDevicesFavouritesFilter: options?.filters || null,
      userId: user?.id || null,
      deviceId: options?.deviceId || null,
    },
    fetchPolicy: options?.fetchPolicy || 'cache-and-network',
  });

  const pageInfo = useMemo(() => {
    return data?.viewer?.allAudioDevicesFavourites?.pageInfo;
  }, [data]);

  const loadedCount = useMemo(() => {
    return data?.viewer?.allAudioDevicesFavourites?.edges?.length;
  }, [data]);

  const count = useMemo(() => {
    return data?.viewer?.allAudioDevicesFavourites?.count;
  }, [data]);

  const mutateData = (edges) =>
    edges?.map((device, index) => {
      return {
        cursor: device.cursor,
        id: device.node.id,
        isOnline: device.node.isOnline,
        lastOnline: device.node.lastOnline,
        description: device.node.description,
        name: device.node.name,
        location: {
          lat: parseFloat(device.node.latitude),
          lng: parseFloat(device.node.longitude),
        },
        currentPlaylist: {
          id: device.node.currentPlaylistId || device.node.currentSceneId,
          name: device.node?.currentPlaylist.name,
          cover: device.node?.currentPlaylist.cover,

          isPlaylist: Boolean(device.node.currentPlaylistId),
          isScene: Boolean(device.node.currentSceneId),
        },
        remotePlaylistMode: device.node.remotePlaylistMode,
        currentSong: {
          id: device.node?.currentSongId,
          title: device.node.currentSongData.node?.title,
          author: device.node.currentSongData.node?.artist,
          album: device.node.currentSongData.node?.album,
          cover: device.node.currentSongData.node?.artworkUrl,
          songReactions: device.node.currentSongData?.songReactions,
          songReaction: device.node.currentSongData?.songReaction,
        },
        info: {
          internalIpAddress: device.node.internalIpAddress,
          publicIpAddress: device.node.publicIpAddress,
          macAddress: device.node.macAddress,
          ethernetMacAddress: device.node.ethernetMacAddress,
          makeAndModel: device.node.makeAndModel,
          lastOnline: device.node.lastOnline,
          latitude: device.node.latitude,
          longitude: device.node.longitude,
        },
        isPlaying: device.node.isPlaying,
        scheduleIds: device.node.scheduleIds,
        remoteVolume: device.node.remoteVolume,
        volume: device.node.volume,
        isFavourite: user?.favouriteDevices?.includes(device.node.id),
        usersId: device.node.usersId,
        manuallyPaused: device.node.manuallyPaused,
        skipCount: device.node.skipCount,
        skipDisabled: device.node.skipDisabled,
        pausedStatus: device.node.pausedStatus,
        clientId: device.node.clientId,
      };
    });

  const allDevices = useMemo(() => {
    return (
      // user?.favouriteDevices &&
      mutateData(data?.viewer?.allAudioDevicesFavourites?.edges)
    );
  }, [data, user]);

  const fetchMoreDevices = async (all) => {
    if (count <= loadedCount) return;
    if (error || !data || !fetchMore || loading) return;
    const { data: fetchMoreData, lodaing: fetchMoreLoading } = await fetchMore({
      variables: {
        allAudioDevicesFavouritesAfter: pageInfo.endCursor,
        allAudioDevicesFavouritesFirst: all
          ? count - loadedCount
          : Math.min(options?.offset, count - loadedCount),
      },

      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const prevEdges = prev?.viewer?.allAudioDevicesFavourites?.edges && [
          ...prev.viewer.allAudioDevicesFavourites.edges,
        ];
        return {
          viewer: {
            ...fetchMoreResult.viewer,
            allAudioDevicesFavourites: {
              ...fetchMoreResult.viewer.allAudioDevicesFavourites,
              edges: [
                ...prevEdges,
                ...fetchMoreResult.viewer.allAudioDevicesFavourites.edges,
              ],
            },
          },
        };
      },
    });
    return { fetchMoreData, fetchMoreLoading };
  };

  const singleDevice = useMemo(
    () => data && allDevices && !error && allDevices[0],
    [allDevices, data, error]
  );

  return {
    singleDevice,
    pageInfo,
    loadedCount,
    allDevices,
    fetchMoreDevices,
    mutateData,
    loading: loading,
    count,
    error,
  };
}
