import React, { useMemo } from 'react';
import * as rdd from 'react-device-detect';

import DashboardLayout from 'layouts/dashboard-layout';

import LoadingProvider from 'components/loading-provider/loading-provider';
import useAllScenes from '../hooks/use-all-scenes';
import CardLink from 'components/card-link/card-link';

import ResultItem from '../../../components/result-item/result-item';
import stc from 'string-to-color';
import { useParams } from 'react-router-dom';
import useAuth from '../../auth/hooks/use-auth';

//create a sort scene function to sort the scenes by name
const sortScenes = (a, b) => {
  const [nameA, nameB] = [a?.name?.toLowerCase(), b?.name?.toLowerCase()];
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

const OnBrandArtistsPage = () => {
  let { id } = useParams();
  const { previewUser, user } = useAuth();
  const path = previewUser ? `user/${id}` : id;
  const { allArtistScenes, loading: loadingScenes } = useAllScenes();

  const userBrandArtists = useMemo(
    () => user?.musicProfile?.artists.filter((item) => item !== '') || [],
    [user?.musicProfile?.artists]
  );

  const artistString = userBrandArtists.join(' / ');

  const scenes = useMemo(
    () => allArtistScenes.sort((a, b) => sortScenes(a, b)) || [],
    [allArtistScenes]
  );

  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle="On brand artists"
      title={artistString}
      modifier="music-search"
    >
      <LoadingProvider loading={loadingScenes}>
        <h4
          style={{
            marginBottom: 20,
            fontSize: 16,
            ...(rdd.isMobileOnly && { marginLeft: 20 }),
          }}
        />
        {rdd.isMobileOnly ? (
          <div className="list-results-mobile">
            {scenes?.map((item) => {
              const to =
                item.type === 'playlist'
                  ? `/${path}/search/playlists/${item.id}`
                  : `/${path}/search/all/scene/${item.id}`;
              return (
                <ResultItem
                  color={stc(item.id)}
                  image={item.cover}
                  key={item.id}
                  to={to}
                  title={item.name}
                  description={item.description}
                  hasImage
                  isMobile
                />
              );
            })}
          </div>
        ) : (
          <div
            className="list-results"
            style={{
              justifyContent: 'space-between',
              marginBottom: 20,
            }}
          >
            {scenes?.map((item) => {
              const to =
                item.type === 'playlist'
                  ? `/${path}/search/playlists/${item.id}`
                  : `/${path}/search/all/scene/${item.id}`;
              return (
                <>
                  <CardLink
                    key={item.id}
                    to={to}
                    title={item.name}
                    cover={item.cover}
                  />
                </>
              );
            })}
          </div>
        )}
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default OnBrandArtistsPage;
