/**
 * External dependencies
 */
import React from 'react';
import { useFormContext } from 'react-hook-form';
/**
 * Internal dependencies
 */
import Field from 'components/field/field';

const FieldValidated = ({
  name,
  type,
  autocomplete,
  placeholder,
  value,
  onChange,
  validations,
  isTextarea,
  style,
}) => {
  const { register, errors } = useFormContext();

  const error = errors[name];
  const isInvalid = !!error;

  return (
    <Field
      style={style}
      isTextarea={isTextarea}
      isInvalid={isInvalid}
      errorMessage={error?.message}
      ref={register(validations)}
      type={type}
      name={name}
      autocomplete={autocomplete}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

export default FieldValidated;
