import React, { useEffect, useMemo, useState } from 'react';
import * as rdd from 'react-device-detect';

import DashboardLayout from 'layouts/dashboard-layout';

import LoadingProvider from 'components/loading-provider/loading-provider';
import useAllScenes from '../hooks/use-all-scenes';
import CardLink from 'components/card-link/card-link';

import ResultItem from '../../../components/result-item/result-item';
import stc from 'string-to-color';
import { useParams } from 'react-router-dom';
import useAuth from '../../auth/hooks/use-auth';

const WhatsYourVibePage = () => {
  let { id } = useParams();
  const { previewUser, user } = useAuth();
  const path = previewUser ? `user/${id}` : id;
  const {
    allScenes,
    allFeaturedScenes,
    loading: loadingScenes,
  } = useAllScenes();

  const userVibes = useMemo(
    () => user?.musicProfile?.vibe || [],
    [allScenes, user?.musicProfile?.vibe]
  );

  const vibesString = userVibes.join(' / ');

  const ScenesByVibe = useMemo(
    () =>
      [...allScenes, ...allFeaturedScenes]?.filter(
        (item) =>
          item.vibe?.filter((item2) => userVibes.includes(item2))?.length > 0
      ),
    [allScenes, userVibes]
  );

  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle="What’s your vibe?"
      title={`${vibesString}`}
      modifier="music-search"
    >
      <LoadingProvider loading={loadingScenes}>
        <h4
          style={{
            marginBottom: 20,
            fontSize: 16,
            ...(rdd.isMobileOnly && { marginLeft: 20 }),
          }}
        />
        {rdd.isMobileOnly ? (
          <div className="list-results-mobile">
            {ScenesByVibe?.map((item) => {
              const to =
                item.type === 'playlist'
                  ? `/${path}/search/playlists/${item.id}`
                  : `/${path}/search/all/scene/${item.id}`;
              return (
                <ResultItem
                  color={stc(item.id)}
                  image={item.cover}
                  key={item.id}
                  to={to}
                  title={item.name}
                  description={item.description}
                  hasImage
                  isMobile
                />
              );
            })}
          </div>
        ) : (
          <div
            className="list-results"
            style={{
              justifyContent: 'space-between',
              marginBottom: 20,
            }}
          >
            {ScenesByVibe?.map((item) => {
              const to =
                item.type === 'playlist'
                  ? `/${path}/search/playlists/${item.id}`
                  : `/${path}/search/all/scene/${item.id}`;
              return (
                <>
                  <CardLink
                    key={item.id}
                    to={to}
                    title={item.name}
                    cover={item.cover}
                  />
                </>
              );
            })}
          </div>
        )}
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default WhatsYourVibePage;
