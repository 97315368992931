/**
 * External dependencies
 */
import React from 'react';
import cx from 'classnames';

/**
 * Internal dependencies
 */
import LoadingProvider from 'components/loading-provider/loading-provider';

const CardLinks = ({
  children,
  large,
  medium,
  small,
  paddedBottom,
  loading,
  title,
}) => {
  return (
    <>
      {title && <h4 className="card-links__title">{title}</h4>}

      <div
        className={cx('card-links', {
          'card-links--large': large,
          'card-links--medium': medium,
          'card-links--small': small,
          'card-links--padded-bottom': paddedBottom,
        })}
      >
        <LoadingProvider loading={loading}>{children}</LoadingProvider>
      </div>
    </>
  );
};

export default CardLinks;
