/**
 * External dependencies
 */
import { useMemo, useContext } from 'react';
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { SINGLE_PLAYLIST } from '../queries/single-playlist';
import AuthContext from 'domain/auth/contexts/auth-context/auth-context';

export default function useSinglePlaylist(
  playlistId,
  songSkip = 0,
  songLimit = 10
) {
  const { previewToken } = useContext(AuthContext);
  const { data, loading, error, fetchMore } = useQuery(SINGLE_PLAYLIST, {
    variables: {
      id: playlistId,
      previewToken: previewToken || null,
      songSkip,
      songLimit,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const playlist = useMemo(
    () =>
      data &&
      !error &&
      data.viewer.allPlaylists.edges.map((playlist) => ({
        id: playlist.node.id,
        name: playlist.node.name,
        cover: playlist.node.cover,
        tracks: playlist.node.tracks,
        description: playlist.node.description,
        allSongs: playlist.node.allSongs,
        featuredArtists: playlist.node.featuredArtists,
      })),
    [data, error]
  );

  return {
    playlist: playlist && playlist[0],
    loading,
    error,
    fetchMore,
  };
}
