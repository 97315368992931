/**
 * External dependencies
 */
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { VIEWABLES } from 'domain/music/queries/viewables';

export default function useViewables(previewId, type) {
  const { data, loading, error } = useQuery(VIEWABLES, {
    variables: {
      previewId: previewId || '',
      type: type || '',
    },
    fetchPolicy: 'cache-and-network',
  });

  return {
    viewables: data?.viewer?.fetchViewables,
    loading,
    error,
  };
}
