import React, { useState, useContext } from 'react';
import BoardingWrapper from '../boarding-wrapper';
import { BoardingContext } from '../context/boardingContext';
import InputBoarding from './input-boarding';

const artists = ['e.g. Talking Heads', 'e.g. Motorhead', 'e.g. Nirvana'];

const Artists = () => {
  const { companyName, handleArtistInputChange, artistValues } =
    useContext(BoardingContext);
  return (
    <BoardingWrapper
      progress={{ firstBar: '100', secondBar: '100', thirdBar: '0' }}
    >
      <div className="modal-boarding">
        <p className="modal-boarding__subtitle">{companyName}</p>
        <p
          className="modal-boarding__title"
          style={{ marginBottom: 0, paddingBottom: 4 }}
        >
          On brand artists
        </p>
        <p className="modal-boarding__subtitle" style={{ paddingBottom: 20 }}>
          Artists that capture the spirit of your brand
        </p>

        {artists.map((artist, index) => (
          <InputBoarding
            value={artistValues[index]}
            onChange={handleArtistInputChange}
            name={index}
            key={artist}
            placeholder={artist}
            style={{ marginBottom: 10 }}
          />
        ))}
      </div>
    </BoardingWrapper>
  );
};

export default Artists;
