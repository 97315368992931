/**
 * External dependencies
 */
import React from 'react';
import { Switch } from 'react-router-dom';
import Page404 from 'views/404/404';

/**
 * External dependencies
 */
import GuestRoute from 'blocks/guest-route/guest-route';

/**
 * Pages
 */
import MusicRoutes from 'domain/music/routes/music-routes';
import SupportPage from 'views/support/support';
import HighlightRoutes from 'domain/highlight/routes/highlight-routes';
import SalesWizard from '../../domain/sales-wizard/sales-wizard';
import MusicRoutesUser from '../../domain/music/routes/music-routes-user';

const Routes = () => {
  return (
    <Switch>
      <GuestRoute exact path="/" component={SalesWizard} />
      <GuestRoute path="/user/:id" component={MusicRoutesUser} />
      <GuestRoute path="/:id?/highlights" component={HighlightRoutes} />
      <GuestRoute path="/:id?/support" component={SupportPage} />
      <GuestRoute path="/:id" component={MusicRoutes} />
      <GuestRoute exact path="/" component={Page404} />
    </Switch>
  );
};

export default Routes;
