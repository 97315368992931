import React, { useContext } from 'react';
import BoardingWrapper from '../boarding-wrapper';
import { BoardingContext } from '../context/boardingContext';
import Tag from './category/tag';
import useUserArtwork from '../hooks/use-user-artwork';

const Explicit = ({ setSelectedStack, stack }) => {
  const { companyName, isExplicit, setIsExplicit } =
    useContext(BoardingContext);
  const { getUserArtwork } = useUserArtwork();
  const images = getUserArtwork?.viewer?.userArtwork || [];
  for (const image of images) {
    const imageElement = new Image();
    imageElement.src = image;
  }
  return (
    <BoardingWrapper
      setSelectedStack={setSelectedStack}
      stack={stack}
      disabled={isExplicit === null}
      progress={{ firstBar: '100', secondBar: '100', thirdBar: '100' }}
    >
      <div className="modal-boarding">
        <p className="modal-boarding__subtitle">{companyName}</p>
        <p className="modal-boarding__title">Explicit content?</p>
        <div style={{ display: 'flex' }}>
          <Tag
            title="Yes"
            isSelected={isExplicit === true}
            handleOnClick={() => setIsExplicit(true)}
          />
          <Tag
            title="No"
            isSelected={isExplicit === false}
            handleOnClick={() => setIsExplicit(false)}
          />
        </div>
      </div>
    </BoardingWrapper>
  );
};

export default Explicit;
