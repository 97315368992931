import React from 'react';
import * as rdd from 'react-device-detect';

import DashboardLayout from 'layouts/dashboard-layout';

import LoadingProvider from 'components/loading-provider/loading-provider';
import useAllScenes from '../hooks/use-all-scenes';
import capitalize from '../../../helpers/capitalize';
import CardLink from 'components/card-link/card-link';
import MusicListItem from '../components/music-list-item';
import useAuth from '../../auth/hooks/use-auth';

const SubCategoryPage = ({
  match: {
    params: { id, subcategory, category },
  },
}) => {
  const { previewUser } = useAuth();
  const path = previewUser ? `user/${id}` : id;
  const { allScenes, allFeaturedScenes } = useAllScenes();

  const filteredScenes =
    [...allFeaturedScenes, ...allScenes]
      ?.filter(
        (scene) =>
          scene.newCategory?.filter(
            (item2) =>
              item2.category.toLowerCase() === category.toLowerCase() &&
              item2?.subCategories
                .map((item3) => item3.toLowerCase())
                .includes(subcategory.toLowerCase())
          )?.length > 0
      )
      .sort((a, b) => {
        if (a?.name < b?.name) {
          return -1;
        }
        if (a?.name > b?.name) {
          return 1;
        }
        return 0;
      }) || [];

  return (
    <DashboardLayout
      isOnMusicRoute
      subtitle="Music"
      title={rdd?.isMobileOnly ? capitalize(subcategory) : capitalize(category)}
      modifier="music-search"
    >
      <LoadingProvider loading={!allScenes}>
        <div style={rdd?.isMobileOnly ? { marginBottom: 135 } : {}}>
          {!rdd?.isMobileOnly && (
            <h4
              style={{
                marginBottom: 20,
                fontSize: 16,
                ...(rdd?.isMobileOnly && { marginLeft: 20 }),
              }}
            >
              {capitalize(subcategory)}
            </h4>
          )}
          {rdd?.isMobileOnly ? (
            <>
              <h4 style={{ fontSize: 13, padding: '0px 0px 15px 13px' }}>
                {filteredScenes.length} Scenes Available
              </h4>

              {filteredScenes?.map((item) => {
                return (
                  <MusicListItem
                    key={item.id}
                    to={`/${path}/search/all/scene/${item?.id}`}
                    cover={item.cover}
                    name={item.name}
                    description={item.description}
                  />
                );
              })}
            </>
          ) : (
            <div
              className="list-results"
              style={{
                justifyContent: 'space-between',
                marginBottom: 20,
              }}
            >
              {filteredScenes?.map((item) => {
                return (
                  <CardLink
                    triggerPlayNow={{
                      playlistId: item?.id,
                      devicePlayType: 'SCENE',
                    }}
                    key={item?.id}
                    to={`/${path}/search/all/scene/${item?.id}`}
                    title={capitalize(item?.name?.toLowerCase())}
                    cover={item?.cover}
                  />
                );
              })}
            </div>
          )}
        </div>
      </LoadingProvider>
    </DashboardLayout>
  );
};

export default SubCategoryPage;
