/**
 * External dependencies
 */
import React, { useEffect } from 'react';

import PreviewWizardLayout from '../../layouts/preview-wizard-layout';

import { useForm } from 'react-hook-form';

/**
 * Internal dependencies
 */
import BoardingContextProvider from './context/boardingContext';
import BoardingModal from './boarding-modal';

const SalesWizard = () => {
  const {
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      companyName: '',
    },
  });

  return (
    <PreviewWizardLayout
      subtitle="Curator"
      title="Sonos"
      backgroundImage={`url(${
        require('../../assets/svg-icons/curator-background.svg').default
      })`}
      modifier="devices"
    >
      <BoardingContextProvider>
        <BoardingModal boardingModal={true} />
      </BoardingContextProvider>
    </PreviewWizardLayout>
  );
};

export default SalesWizard;
