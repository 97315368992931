/**
 * External dependencies
 */
import React from 'react';
import { createPortal } from 'react-dom';

/**
 * Internal dependencies.
 */
import Button from 'components/button/button';
import portalsContainer from 'helpers/portals-container';
import { ReactComponent as IcoX } from 'assets/svg-icons/ico-x.svg';

const Modal = ({
  subtitle,
  title,
  children,
  visible,
  styles,
  onClose = () => {},
}) => {
  if (!visible) return null;

  return createPortal(
    <div className="modal">
      {/* <Button type="button" onClick={onClose} className="modal__close">
        <IcoX />
      </Button> */}

      <div className="modal__inner" style={{ ...styles }}>
        <div className="modal__head">
          {subtitle && <h5>{subtitle}</h5>}

          {title && <h2>{title}</h2>}
        </div>

        <div className="modal__body">{children}</div>
      </div>
    </div>,
    portalsContainer
  );
};

export default Modal;
