/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const USER_PREVIEW_ACTION = gql`
  mutation userPreviewAction($action: String!) {
    userPreviewAction(action: $action)
  }
`;
