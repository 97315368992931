/**
 * External dependencies
 */
import React, { useState } from 'react';
import stc from 'string-to-color';
import * as rdd from 'react-device-detect';

/**
 * Internal dependencies
 */
import LoadingProvider from 'components/loading-provider/loading-provider';
import useAllPlaylists from '../hooks/use-all-playlists';
import Button from 'components/button/button';
import ResultItem from 'components/result-item/result-item';
import getRandomColor from 'helpers/get-random-color';
import _ from 'lodash';
import useAuth from '../../auth/hooks/use-auth';
import Card from '../components/card';

const AllIntensities = ({ deviceId }) => {
  const { previewUser } = useAuth();
  const path = previewUser ? `user/${deviceId}` : deviceId;
  const { allPlaylists, loading } = useAllPlaylists();

  const [allIntensities, setAllIntensities] = useState([
    {
      name: 'Downtempo',
      color: stc('Downtempo'),
      expanded: false,
    },
    {
      name: 'Midtempo',
      color: stc('Midtempo'),
      expanded: false,
    },
    {
      name: 'Uptempo',
      color: stc('Uptempo'),
      expanded: false,
    },
    {
      name: 'Various',
      color: stc('Various'),
      expanded: false,
    },
  ]);

  return (
    <LoadingProvider loading={loading}>
      <div
        style={
          rdd?.isMobileOnly
            ? {
                width: '100vw',
                marginBottom: 135,
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridGap: 13,
                padding: '0px 13px',
              }
            : {}
        }
      >
        {!loading &&
          allIntensities?.map((intensity, index) => {
            const filteredPlaylists = allPlaylists
              ?.filter((playlist) => playlist.tempo === intensity?.name)
              .sort((a, b) => {
                const nameA = a?.name?.toUpperCase(); // ignore upper and lowercase
                const nameB = b?.name?.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                // names must be equal
                return 0;
              });

            return rdd?.isMobileOnly ? (
              <Card
                index={index}
                key={intensity.name}
                title={intensity.name}
                to={`/${path}/search/all/intensities/${intensity.name}`}
              />
            ) : (
              <section className="section-search-result" key={intensity?.name}>
                <div className="section-search-result__head">
                  <h4>{intensity.name}</h4>

                  {!intensity.expanded && (
                    <Button
                      modifier="as-link"
                      onClick={() => {
                        let tempIntensities = [...allIntensities];

                        tempIntensities[index] = {
                          ...tempIntensities[index],
                          expanded: true,
                        };

                        setAllIntensities(tempIntensities);
                      }}
                    >
                      Expand
                    </Button>
                  )}
                </div>

                <div className="list-results-small">
                  {filteredPlaylists
                    ?.splice(
                      0,
                      intensity.expanded ? filteredPlaylists.length : 6
                    )
                    .map((item) => (
                      <ResultItem
                        color={getRandomColor(_.random(14))}
                        // image={item.cover}
                        key={item.id}
                        to={`/${path}/search/playlists/${item?.id}`}
                        title={item?.name}
                        image={item?.cover}
                        description={item?.genre?.join(' / ')}
                        hasImage
                      />
                    ))}
                </div>
              </section>
            );
          })}
      </div>
    </LoadingProvider>
  );
};

export default AllIntensities;
